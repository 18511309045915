import { combineReducers } from "redux";
import UserReducer from "./UserReducer";
import { errorSlice } from "./ErrorReducer";
import { examTypeSlice } from "./ExamTypeReducer";
import { subjectSlice } from "./SubjectReducer";
import { yearSlice } from "./YearReducer";
import { examSlice } from "./ExamReducer";
import { leaderboardSlice } from "./LeaderboardReducer";
import { resultSlice } from "./ResultReducer";
import { gradeSlice } from "./GradeReducer";
import { candidateSlice } from "./CandidatesReducer";

const rootReducer = combineReducers({
	auth: UserReducer,
	error: errorSlice.reducer,
	examType: examTypeSlice.reducer,
	subject: subjectSlice.reducer,
	year: yearSlice.reducer,
	exam: examSlice.reducer,
	leaderboard: leaderboardSlice.reducer,
	result: resultSlice.reducer,
	grade: gradeSlice.reducer,
	candidates: candidateSlice.reducer,
});

export default rootReducer;
