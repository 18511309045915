import { createSlice } from "@reduxjs/toolkit";
import { clearErrors, returnErrors } from "./ErrorReducer";
import axios from "axios";
import { toast } from "react-toastify";
import { DeleteData, EditData } from "./UserReducer";

let initialState = {
	data: null,
	search: "",
	status: "",
	mainSearch: null,
	isFound: null,
};

export const subjectSlice = createSlice({
	name: "subject",
	initialState,
	reducers: {
		getSearchSubject: (state, { payload }) => {
			state.mainSearch =
				payload?.search === state?.search
					? payload?.data || payload
					: state?.mainSearch;
			state.search_paginate =
				payload?.search === state?.search
					? payload?.paginate || null
					: state?.search_paginate;
			state.isFound = true;
		},
		getSearch: (state, { payload }) => {
			state.search = payload?.search || payload;
		},
		resetSubjectSearch: state => {
			state.search = "";
			state.mainSearch = [];
			state.search_paginate = null;
			state.isFound = false;
		},
		getSubject: (state, { payload }) => {
			state.data = payload?.data || payload;
			state.paginate = payload?.paginate;
		},
		addSubject: (state, { payload }) => {
			let data = payload?.data || payload;

			state.data = {
				...state?.data,
				docs: [data, ...state?.data?.docs],
			};

			state.status = "added";
		},
		deleteSubject: (state, { payload }) => {
			let data = payload?.data || payload;
			state.status = "deleted";
			state.data = {
				...state?.data,
				docs: DeleteData(state?.data?.docs, data),
			};
		},
		updateSubject: (state, { payload }) => {
			let data = payload?.data || payload;
			state.status = "deleted";
			state.data = {
				...state?.data,
				docs: EditData(state?.data?.docs, data),
			};
		},
		subjectFail: state => {
			state.status = "";
			state.isFound = null;
		},
		logoutSubject: state => {
			state.status = "";
			state.isFound = null;
			state.data = null;
			state.mainSearch = null;
			state.paginate = null;
		},
	},
});
export const {
	subjectFail,
	getSubject,
	deleteSubject,
	addSubject,
	getSearchSubject,
	getSearch,
	resetSubjectSearch,
	updateSubject,
	logoutSubject,
} = subjectSlice.actions;

export const manageSubject = (type, data) => async dispatch => {
	dispatch(clearErrors());
	try {
		let res;

		if (type === "get") {
			res = await axios.get(
				`/api/v1/subject?type=all${data?.limit ? `&limit=${data?.limit}` : ""}`
			);
			dispatch(getSubject(res.data));
		}
		if (type === "post") {
			res = await axios.post(`/api/v1/subject`, {
				...data,
			});
			dispatch(addSubject(res?.data));
		}
		if (type === "put") {
			res = await axios.put(`/api/v1/subject?subject=${data?._id}`, {
				...data,
			});
			dispatch(updateSubject(res?.data));
		}
		if (type === "delete") {
			res = await axios.delete(`/api/v1/subject?subject=${data?._id}`);
			dispatch(deleteSubject(data));
		}
		if (type !== "get") {
			toast.success(res?.data?.message);
			setTimeout(() => {
				dispatch(subjectFail());
			}, 1000);
		}
	} catch (err) {
		if (err) console.log({ error: err.response?.data, err });
		if (err?.response?.status === 429) toast.error(err?.response?.data);
		dispatch(subjectFail());
		if (type && type !== "get") {
			let error = err.response?.data?.error;
			if (error && error?.length > 1) {
				dispatch(returnErrors({ error, status: err?.response?.status }));
			} else {
				toast.error(err?.response?.data?.message || err?.message);
			}
		}
	}
};
