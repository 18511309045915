import React, { useState, useEffect } from "react";
import Title from "../../components/title/title";
import LeaderBoardTable from "../../components/Table/leaderboard";
import { useSelector, useDispatch } from "react-redux";
import { manageLeaderboard } from "../../data/Reducers/LeaderboardReducer";
import { LoadingLoader } from "../../components/button/button";

const Leaderboard = () => {
	const tableData = [
		{
			id: 25,
			name: "username",
			date: "26/06/2023",
			email: "username@gmail.com",
			score: 1000,
			rank: "20th",
		},
		{
			id: 25,
			name: "username",
			date: "26/06/2023",
			email: "username@gmail.com",
			score: 1000,
			rank: "20th",
		},
		{
			id: 25,
			name: "username",
			date: "26/06/2023",
			email: "username@gmail.com",
			score: 1000,
			rank: "20th",
		},
		{
			id: 25,
			name: "username",
			date: "26/06/2023",
			email: "username@gmail.com",
			score: 1000,
			rank: "20th",
		},
		{
			id: 25,
			name: "username",
			date: "26/06/2023",
			email: "username@gmail.com",
			score: 1000,
			rank: "20th",
		},
		{
			id: 25,
			name: "username",
			date: "26/06/2023",
			email: "username@gmail.com",
			score: 1000,
			rank: "20th",
		},
		{
			id: 25,
			name: "username",
			date: "26/06/2023",
			email: "username@gmail.com",
			score: 1000,
			rank: "20th",
		},
		{
			id: 25,
			name: "username",
			date: "26/06/2023",
			email: "username@gmail.com",
			score: 1000,
			rank: "20th",
		},
		{
			id: 25,
			name: "username",
			date: "26/06/2023",
			email: "username@gmail.com",
			score: 1000,
			rank: "20th",
		},
		{
			id: 25,
			name: "username",
			date: "26/06/2023",
			email: "username@gmail.com",
			score: 1000,
			rank: "20th",
		},
		{
			id: 25,
			name: "username",
			date: "26/06/2023",
			email: "username@gmail.com",
			score: 1000,
			rank: "20th",
		},
	];

	let [type, setType] = useState("all");

	let { leaderboard } = useSelector(s => s),
		[data, setData] = useState(null),
		dispatch = useDispatch();

	useEffect(() => {
		setData(
			type === "month"
				? leaderboard?.month
				: type === "day"
				? leaderboard?.day
				: leaderboard?.data
		);
	}, [leaderboard?.data, type, leaderboard?.day, leaderboard?.month]);

	useEffect(() => {
		dispatch(manageLeaderboard("get", { type }));
	}, [dispatch, type]);

	if (!data && !leaderboard?.data) return <LoadingLoader />;

	return (
		<div className="w-[85%] m-auto">
			<div>
				<Title
					title="LEADERBOARD ACTIVITY"
					description="View Leaderboard performance"
				/>
			</div>
			<div className="">
				<div className="mt-8 flex flex-col gap-y-3">
					<label
						htmlFor="choose-category"
						className="text-lg text-[#1B1B1B] font-medium">
						Choose Category
					</label>
					<select
						value={type}
						onChange={e => setType(e.target.value)}
						id="choose-category"
						className="w-80 rounded-md outline-none border-[#CCCCCC]">
						<option value="all">All Time</option>
						<option value="day">Daily</option>
						<option value="month">Monthly</option>
					</select>
				</div>

				<div className="bg-white shadow-lg ml-6 mt-10 p-4">
					<LeaderBoardTable data={data || tableData} />
				</div>
			</div>
		</div>
	);
};

export default Leaderboard;
