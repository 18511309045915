/* eslint-disable no-undef */
import { createElement, useEffect } from "react";
import { useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
// import { ErrorPage } from "./components";
import { GlobalState } from "./data/Context";

const GeneratePage = (pageName, folder) => {
	const component = () => require(`./${folder}/${pageName}`).default;
	let navigate = useNavigate();
	try {
		return createElement(component());
	} catch (e) {
		console.log(e);
		navigate("/");
	}
};

const PageRender = () => {
	const { auth } = useContext(GlobalState);
	const { page, id, step } = useParams();
	const escape2 = [
			"view-tasks",
			"tutor-action",
			"profile",
			"student-action",
			"question",
			"subjects",
			"edit",
			"result",
		],
		navigate = useNavigate();

	useEffect(() => {
		// if (!auth?.isAuth) {
		// 	if (!error?.errorText) {
		// 		if (page !== "login" && page !== "register") {
		// 			navigate("/");
		// 		}
		// 		clearErrors();
		// 	}
		// }
		if (auth?.isAuth) {
			if (["register", "login", "create-account"]?.includes(page)) {
				navigate("/");
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, auth?.isAuth, navigate]);

	if (auth.token && auth.loading) return <></>;
	// if (general?.isLoading && users.isLoading) return <Loader />;

	let pageName = "";
	if (step) {
		pageName = `${page}/${id}/${"[id]"}`;
	} else if (id) {
		if (
			(page === "students" && escape2.includes(id)) ||
			(page === "tutors" && escape2.includes(id)) ||
			(page === "login" && escape2.includes(id)) ||
			(page === "exams" && escape2.includes(id)) ||
			(page === "manage-exams" && escape2.includes(id)) ||
			(page === "view-results" && escape2.includes(id))
		) {
			pageName = `${page}/${id}`;
		} else {
			pageName = `${page}/${"[id]"}`;
		}
	} else {
		pageName = `${page}`;
	}
	return GeneratePage(pageName, auth?.isAuth ? "pages" : "screens");
};

export default PageRender;
