// Pagination.js
import React, { useState } from 'react';

const Pagination = ({ totalQuestions }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const goToPage = (page) => {
    setCurrentPage(page);
  };

  const renderPaginationButtons = () => {
    const buttons = [];
    for (let i = 1; i <= totalQuestions; i++) {
      buttons.push(
        <div className='mt-4 flex justify-center items-center mb-2'>
          <button
            key={i}
            onClick={() => goToPage(i)}
            className={`mx-1 px- py-1  ${
              i === currentPage ? 'bg-[#378C36] text-white rounded-tl-lg cursor-pointer w-12 h-12 ' : ' text-black'
            } rounded-full`}
          >
            {i}
          </button>
        </div>
      );
    }
    return buttons;
  };

  return (
    <div className="mt-4 ">
      <div className="grid grid-cols-5 gap-x-2 justify-center items-center font-bold">
       {renderPaginationButtons()}
      </div>
    </div>
  );
};

export default Pagination;







// import React, { useState } from 'react';
// import { HiPlus } from "react-icons/hi";


// const SquarePagination = ({onClick}) => {
//   const [currentPage, setCurrentPage] = useState(1);
//   const [items, setItems] = useState([
//     1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]);

//   const totalPages = Math.ceil(items.length);

//   const handleAddItem = () => {
//     const newItem = items.length + 1;
//     setItems([...items, newItem]);
//   };

//   return (
//     <div className="p-4">
//         <h1 className='text-center font-bold text-[20px] mb-4'>Question Number</h1>
//       <div className="grid grid-cols-5 gap-x-2 justify-center items-center font-bold">
//         {items
//           .slice((currentPage - 1) * totalPages, currentPage * totalPages)
//           .map((item) => (
//             <div
//               key={item}
//               className="w-12 h-12 hover:bg-[#378C36]  text-black hover:text-white flex items-center justify-center rounded-l-lg cursor-pointer"
//             >
//               {item}
//             </div>
//           ))}

//         <div className="mt-4 flex justify-center items-center mb-2">
//             <button
//             className="p-2 bg-[#FD5709] text-white rounded-full "
//             onClick={handleAddItem}
//             >
//             <HiPlus className='icon font-bold' size={24} />
//             </button>
//         </div>
//       </div>
//       {/* <div className="mt-4 flex justify-center">
//          <button
//           className="px-4 py-2 bg-blue-500 text-white rounded-md mr-2"
//           onClick={() => setCurrentPage(currentPage - 1)}
//           disabled={currentPage === 1}
//         >
//           Previous
//         </button>
//         <button
//           className="px-4 py-2 bg-blue-500 text-white rounded-md"
//           onClick={() => setCurrentPage(currentPage + 1)}
//           disabled={currentPage === totalPages}
//         >
//           Next
//         </button>
//       </div> */}
//     </div>
//   );
// };

// export default SquarePagination;
