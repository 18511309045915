import React from "react";
import { Link } from "react-router-dom";
import Button from "../button/button";

const CardList = ({ title, icon, to, item }) => {
	// const params = useParams();

	return (
		<div className="bg-white shadow-lg">
			<Link to={to} state={item}>
				<div className="max-w-sm mx-auto rounded overflow-hidden cursor-pointer transition transform hover:scale-105 flex justify-center items-center flex-col">
					<img className="w-20 h-20 rounded-full" src={icon} alt="Card Img" />
					<div className="px-6 py-4">
						{/* <div className="font-bold text-xl mb-2"></div> */}
						<p className="text-[#1B1B1B] text-[20px] font-bold uppercase">
							{title}
						</p>
					</div>
				</div>
			</Link>
			<div className="flex justify-end">
				<Button datum={item} />
			</div>
		</div>
	);
};

export default CardList;
