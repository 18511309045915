import React, {useState} from 'react'; 
import { AiOutlineDelete } from "react-icons/ai";
import Delete from '../modal/delete';

const ButtonDelete = ({ datum }) => {
	const [isModalOpenDelete, setIsModalOpenDelete] = useState(false);

	const openModalDelete = () => {
		setIsModalOpenDelete(true);
	};

	const closeModalDelete = () => {
		setIsModalOpenDelete(false);
	};
	return (
		<div className="">
			<div
				className="flex justify-end items-center gap-x-2 mt-8 cursor-pointer"
				onClick={openModalDelete}>
				<AiOutlineDelete className="icon  text-[#F32424]" size={16} />
				<span className="text-[16px] font-bold">Delete</span>
			</div>
			<Delete
				isOpenDelete={isModalOpenDelete}
				closeModalDelete={closeModalDelete}
				type="Subject Exam Type"
				datum={datum}
			/>
		</div>
	);
};

export default ButtonDelete; 
