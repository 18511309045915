import React from "react";
import EditDeleteBtn, { EditDeleteYearBtn } from "../button/EditDeleteBtn";

const CardListTwo = ({ title, icon, to, item, type = "Subject" }) => {
	return (
		<div className="bg-white shadow-lg py-6">
			<div>
				<div>
					<div className="max-w-sm mx-auto rounded overflow-hidden cursor-pointer transition transform hover:scale-105 flex justify-center items-center flex-col">
						<img className="w-20" src={icon} alt="Card Img" />
						<div className="px-6 py-4">
							{/* <div className="font-bold text-xl mb-2"></div> */}
							<p className="text-[#1B1B1B] text-[20px] font-bold uppercase text-center">
								{title}
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className="mt-6">
				{type === "Year" ? (
					<EditDeleteYearBtn datum={item} />
				) : (
					<EditDeleteBtn datum={item} />
				)}
			</div>
		</div>

		// <div className="grid grid-cols-4 gap-6 justify-center items-center ">
		//   {cards.map((card, index) => (
		//     <div key={index} className="bg-white rounded-lg shadow-md p-4">
		//       <div className='flex flex-col justiify-center items-center w-[250px] h-[150px]'>
		//         <img src={card.icon} alt="Card Image" className="icon mt-6" sizes={24} />
		//         <h1 className='font-semibold mt-12 text-[24px] text-center'>{card.title}</h1>
		//       </div>
		//     </div>
		//   ))}
		// </div>
	);
};

export default CardListTwo;
