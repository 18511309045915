import React from 'react'
import Title from '../../components/title/title'; 
import TestScored from '../../components/testscored/testscored';
import TimeSpent from '../../components/timespent/timespent';
import VisitedQuestion from '../../components/vistedquestion/visitedquestion';
import VisitedAnswer from '../../components/visitedanswer/visitedanswer';
import CorrectAnswers from '../../components/corrected/corrected';

const Results = () => {
  const score = 20; 
  const timeSpent = Number("00:30:01")
  const question = 50;
  const answer = 50;
  const correct = 20



  return (
    <div className='bg-[#F4F7FE] w-full h-screen'>
      <div className='w-[85%] m-auto'>
        <div className='py-8'>
          <Title title="VIEW RESULTS" description="Debbie Georges's results" />
          <div className='ml-6 mt-14 shadow-lg bg-white p-4 rounded-lg'>
            <div className='flex gap-x-4 items-center '>
              <img src={require('../../assets/party_popper.png')} alt="emoji" className='icon w-12' sizes={24} />
              <h1 className='font-bold text-[24px]'>Debbie Results:</h1>
            </div>

            <div className='flex gap-x-4 font-bold text-[18px] mt-4'>
              <h1 className='bg-white shadow-md p-3'>Subject: English</h1>
              <h1 className='bg-white shadow-md p-3'>Exam Name: UTME</h1>
              <h1 className='bg-white shadow-md p-3'>Year: 2012</h1>
            </div>
            {/*  */}


            <div className='mt-5 flex gap-x-20'>
              {/*  */}
              <div className=''>
                <div className='bg-white shadow-md w-44 p-4 text-center rounded-md mb-4'>
                  <h1 className='font-bold text-[14.25px] mb-1'>Your Score</h1>
                  <span className='text-[17.81px] text-[#2E69FF] font-bold'>40/50</span>
                </div>
                <div className='bg-white shadow-md w-44 p-3 text-center rounded-md mb-4'>
                  <h1 className='font-bold text-[14.25px] mb-1'>Time Spent</h1>
                  <span className='text-[17.81px] text-[#2E69FF] font-bold'>00:30:01</span>
                </div>
                <div className='bg-white shadow-md w-44 p-3 text-center rounded-md mb-4'>
                  <h1 className='font-bold text-[14.25px] mb-1'>Correct Answers</h1>
                  <span className='text-[17.81px] text-[#2E69FF] font-bold'>20</span>
                </div>
                <div className='bg-white shadow-md w-44 p-3 text-center rounded-md mb-4'>
                  <h1 className='font-bold text-[14.25px] mb-1'>Exam Date & Time</h1>
                  <span className='text-[17.81px] text-[#2E69FF] font-bold'>30.03.2023 | 05:30</span>
                </div>
              </div>

              {/*  */}
              <div className='w-full'>
                <div className='mb-10'>
                    <TestScored score={score} />
                </div>
                <div className='mb-10'>
                  <TimeSpent timeSpent={timeSpent} />
                </div>
                <div className='mb-10'>
                  <VisitedQuestion question={question} />
                </div>
                <div className='mb-10'>
                  <VisitedAnswer answer={answer} />
                </div>
                <div className='mb-10'>
                  <CorrectAnswers correct={correct} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Results
