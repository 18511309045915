import React, { useState } from 'react';

const SelectField = () => {
  const [selectedOption, setSelectedOption] = useState('option1'); // Set the initial selected option

  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
  };

  return (
    <div className="w-64 ">
      <select
        id="selectField"
        className="w-full bg-white text-[#FD5709] font-semibold border-2 border-[#FD5709] rounded-md py-2 px-4 focus:outline-none focus:ring focus:border-{#FD5709]"
        value={selectedOption}
        onChange={handleSelectChange}
      >
        <option value="option1">-Select Exam-</option>
        <option value="option2">Option 2</option>
        <option value="option3">Option 3</option>
        <option value="option4">Option 4</option>
      </select>
    </div>
  );
};

export default SelectField;
