import React from 'react';

const Success = ({isOpenSuccess, closeSuccess}) => {
    if (!isOpenSuccess) return null; 
        
  return (
    <div>
        <div className='fixed top-0 left-0 z-50 w-full h-full bg-[rgba(0,0,0,0.2)] flex flex-col items-center justifty-center' onClick={closeSuccess}>
            <div className='relative w-1/3 bg-white shadow-lg m-auto flex flex-col items-center justifty-center'>
                <img src={require('../../assets/successful.png')} alt="successful" />

                <div className='flex items-center gap-x-3 mb-8'>
                    <h3 className='text-3xl font-bold'>SUCCESS! Well done</h3>
                    <img src={require('../../assets/thumbs up.png')} alt="thumb up" className='icon' sizes={36} />
                </div>
            </div>
        </div>
    </div>
  )
}

export default Success; 
