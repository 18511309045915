import React, {useState} from 'react'; 
import Title from '../../components/title/title';
import AddButton from '../../components/button/addbutton';
import CardListThree from '../../components/card/cardThree';
import SelectModal from '../../components/modal/selectModal';

const ManageSubject = () => {
  const [isSelectModalOpen, setIsSelectModalOpen] = useState(false);

  const cards  = [
      {
        icon: '../../assets/question.png', 
        title: 'BIOLOGY STUDIES', 
        id: 1,
      }, 
      {
        icon: '../../assets/questions.png', 
        title: 'MATHEMATICS', 
        id: 2, 
      }, 
      {
        icon: '../../assets/questions.png', 
        title: 'ENGLISH LANGUAGE', 
        id: 3, 
      }, 
      {
        icon: '../../assets/circle-questionmark.png', 
        title: 'COMPUTER SCIENCE', 
        id: 4, 
      }, 
      {
        icon: '../../assets/questions.png', 
        title: 'CATERING & ART',
        id: 5
      }, 
      {
        icon: '../../assets/questions.png', 
        title: 'YORUBA LANGUAGE', 
        id: 6
      }, 
      {
        icon: '../../assets/questions.png', 
        title: 'CHEMISTRY',
        id: 7
      }, 
      {
        icon: '../../assets/questions.png', 
        title: 'LIT-IN ENGLISH',
        id: 8
      }, 
      {
        icon: '../../assets/questions.png', 
        title: 'HOME ECONOMICS', 
        id: 9
      }, 
      {
        icon: '../../assets/questions.png', 
        title: 'MATHEMATICS', 
        id: 10
      }, 
      {
        icon: '../../assets/questions.png', 
        title: 'ENGLISH LANGUAGE', 
        id: 11
      }, 
      {
        icon: '../../assets/questions.png', 
        title: 'COMPUTER SCIENCE', 
        id: 12
      },
  ]; 

  const selectOpenModal = () =>{
    setIsSelectModalOpen(true)
  }

  const closeSelectModal = () =>{
    setIsSelectModalOpen(false)
  }

  return (
    <div>
        <div className=' w-[85%] m-auto'>
            <Title title="MANAGE SUBJECTS" />       
            <div className='ml-6 mt-6 mb-8 flex justify-between items-center'>
                <h2 className='text-2xl'>All Subjects</h2>
                <AddButton onClick={selectOpenModal}  />
            </div>
            <div>
                <CardListThree cards={cards} />
            </div>
            <SelectModal isOpenSelectModal={isSelectModalOpen}  />
        </div>

    </div>
  )
}

export default ManageSubject
