import { createSlice } from "@reduxjs/toolkit";
import { clearErrors, returnErrors } from "./ErrorReducer";
import axios from "axios";
import { toast } from "react-toastify";
import { DeleteData, EditData } from "./UserReducer";

let initialState = {
	data: null,
	search: "",
	status: "",
	mainSearch: null,
	isFound: null,
};

export const examSlice = createSlice({
	name: "exam",
	initialState,
	reducers: {
		getSearchExam: (state, { payload }) => {
			state.mainSearch =
				payload?.search === state?.search
					? payload?.data || payload
					: state?.mainSearch;
			state.search_paginate =
				payload?.search === state?.search
					? payload?.paginate || null
					: state?.search_paginate;
			state.isFound = true;
		},
		getSearch: (state, { payload }) => {
			state.search = payload?.search || payload;
		},
		resetExamSearch: state => {
			state.search = "";
			state.mainSearch = [];
			state.search_paginate = null;
			state.isFound = false;
		},
		getExam: (state, { payload }) => {
			state.data = payload?.data || payload;
			state.paginate = payload?.paginate;
		},
		addExam: (state, { payload }) => {
			let data = payload?.data || payload;

			state.data = {
				...state?.data,
				docs: [data, ...state?.data?.docs],
			};

			state.status = "added";
		},
		deleteExam: (state, { payload }) => {
			let data = payload?.data || payload;
			state.status = "deleted";
		state.data = {
			...state?.data,
			docs: DeleteData(state?.data?.docs, data),
		};
		},
		updateExam: (state, { payload }) => {
			let data = payload?.data || payload;
			state.status = "deleted";
			state.data = {
				...state?.data,
				docs: EditData(state?.data?.docs, data),
			};
		},
		examFail: state => {
			state.status = "";
			state.isFound = null;
		},
		logoutExam: state => {
			state.status = "";
			state.isFound = null;
			state.data = null;
			state.mainSearch = null;
			state.paginate = null;
		},
	},
});
export const {
	examFail,
	getExam,
	deleteExam,
	addExam,
	getSearchExam,
	getSearch,
	resetExamSearch,
	updateExam,
	logoutExam,
} = examSlice.actions;

export const manageExam = (type, data) => async dispatch => {
	dispatch(clearErrors());
	try {
		let res;

		if (type === "get") {
			res = await axios.get(
				`/api/v1/exam?type=all${data?.limit ? `&limit=${data?.limit}` : ""}`
			);
			dispatch(getExam(res.data));
		}
		if (type === "post") {
			res = await axios.post(`/api/v1/exam`, {
				...data,
			});
			dispatch(addExam(res?.data));
		}
		if (type === "put") {
			res = await axios.put(`/api/v1/exam?exam=${data?._id}`, {
				...data,
			});
			dispatch(updateExam(res?.data));
		}
		if (type === "delete") {
			res = await axios.delete(`/api/v1/exam?exam=${data?._id}`);
			dispatch(deleteExam(data));
		}
		if (type !== "get") {
			toast.success(res?.data?.message);
			setTimeout(() => {
				dispatch(examFail());
			}, 1000);
		}
	} catch (err) {
		if (err) console.log({ error: err.response?.data, err });
		if (err?.response?.status === 429) toast.error(err?.response?.data);
		dispatch(examFail());
		if (type && type !== "get") {
			let error = err.response?.data?.error;
			if (error && error?.length > 1) {
				dispatch(returnErrors({ error, status: err?.response?.status }));
			} else {
				toast.error(err?.response?.data?.message || err?.message);
			}
		}
	}
};
