import React, { useState, useEffect } from "react";
import Title from "../../components/title/title";
import CardListTwo from "../../components/card/cardTwo";
// import SelectModal from "../../components/modal/selectModal";
import AddButton from "../../components/button/addbutton";
import Img from "../../assets/subjects.png";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { manageSubject } from "../../data/Reducers/SubjectReducer";
import { LoadingLoader } from "../../components/button/button";
import { AddSubjectModal } from "../../components/modal/addModal";

const ManageSubjects = () => {
	const [isSelectModalOpen, setIsSelectModalOpen] = useState(false);

	// const cardsData = [
	// 	{
	// 		icon: Img,
	// 		title: "BIOLOGY STUDIES",
	// 		id: 1,
	// 	},
	// 	{
	// 		icon: Img,
	// 		title: "MATHEMATICS",
	// 		id: 2,
	// 	},
	// 	{
	// 		icon: Img,
	// 		title: "ENGLISH LANGUAGE",
	// 		id: 3,
	// 	},
	// 	{
	// 		icon: Img,
	// 		title: "COMPUTER SCIENCE",
	// 		id: 4,
	// 	},
	// 	{
	// 		icon: Img,
	// 		title: "CATERING & ART",
	// 		id: 5,
	// 	},
	// 	{
	// 		icon: Img,
	// 		title: "YORUBA LANGUAGE",
	// 		id: 6,
	// 	},
	// 	{
	// 		icon: Img,
	// 		title: "CHEMISTRY",
	// 		id: 7,
	// 	},
	// 	{
	// 		icon: Img,
	// 		title: "LIT-IN ENGLISH",
	// 		id: 8,
	// 	},
	// 	{
	// 		icon: Img,
	// 		title: "HOME ECONOMICS",
	// 		id: 9,
	// 	},
	// 	{
	// 		icon: Img,
	// 		title: "MATHEMATICS",
	// 		id: 10,
	// 	},
	// 	{
	// 		icon: Img,
	// 		title: "ENGLISH LANGUAGE",
	// 		id: 11,
	// 	},
	// 	{
	// 		icon: Img,
	// 		title: "COMPUTER SCIENCE",
	// 		id: 12,
	// 	},
	// ];

	const selectOpenModal = () => {
		setIsSelectModalOpen(true);
	};

	// const closeSelectModal = () =>{
	//   setIsSelectModalOpen(false)
	// }

	let { subject } = useSelector(s => s),
		[data, setData] = useState(null),
		dispatch = useDispatch(),
		navigate = useNavigate();

	useEffect(() => {
		setData(subject?.data);
	}, [subject?.data]);

	useEffect(() => {
		dispatch(manageSubject("get", null));
	}, [dispatch]);

	if (!data && !subject?.data) return <LoadingLoader />;

	return (
		<div className="w-[85%] m-auto">
			<div className="">
				<Title title="MANAGE SUBJECTS" />
				<div className="mt-6 mb-8 flex justify-between items-center gap-4">
					<h2 className="text-2xl">All Subjects</h2>
					<div className="flex items-center">
						<AddButton
							type="view"
							onClick={() => navigate("/manage-year")}
							title="Manage Years"
						/>
						<AddButton onClick={selectOpenModal} />
					</div>
				</div>

				<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8 mt-8 ">
					{data?.docs?.map((card, index) => (
						<CardListTwo
							key={index}
							title={card?.title}
							icon={Img}
							to="/manage-exams/question"
							item={card}
						/>
					))}
				</div>

				{/* <div>
                <CardListTwo cards={cards} />
            </div> */}
			</div>
			<AddSubjectModal
				isOpenAddModal={isSelectModalOpen}
				closeAddModal={() => setIsSelectModalOpen(false)}
				isOpenSelectModal={isSelectModalOpen}
			/>
		</div>
	);
};

export default ManageSubjects;
