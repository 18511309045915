import React from 'react'

const Title = (props) => {
  return (
    <div>
       <div className='font-bold mt-[3rem] '>
            <h1 className='text-lg mt-6 font-bold '>{props.title}</h1>
            <p className='mt-6 text-2xl'>{props.description}</p>
        </div>
    </div>
  )
}

export default Title; 
