import React, { useState, useEffect } from "react";
import Title from "../../components/title/title";
import AddButton from "../../components/button/addbutton";
import CardListThree from "../../components/card/cardThree";
import SelectModal from "../../components/modal/selectModal";
import Img from "../../assets/subjects.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ManageSubject = () => {
	const [isSelectModalOpen, setIsSelectModalOpen] = useState(false);

	// const cardsData = [
	// 	{
	// 		icon: Img,
	// 		title: "BIOLOGY STUDIES",
	// 		id: 1,
	// 	},
	// 	{
	// 		icon: Img,
	// 		title: "MATHEMATICS",
	// 		id: 2,
	// 	},
	// 	{
	// 		icon: Img,
	// 		title: "ENGLISH LANGUAGE",
	// 		id: 3,
	// 	},
	// 	{
	// 		icon: Img,
	// 		title: "COMPUTER SCIENCE",
	// 		id: 4,
	// 	},
	// 	{
	// 		icon: Img,
	// 		title: "CATERING & ART",
	// 		id: 5,
	// 	},
	// 	{
	// 		icon: Img,
	// 		title: "YORUBA LANGUAGE",
	// 		id: 6,
	// 	},
	// 	{
	// 		icon: Img,
	// 		title: "CHEMISTRY",
	// 		id: 7,
	// 	},
	// 	{
	// 		icon: Img,
	// 		title: "LIT-IN ENGLISH",
	// 		id: 8,
	// 	},
	// 	{
	// 		icon: Img,
	// 		title: "HOME ECONOMICS",
	// 		id: 9,
	// 	},
	// 	{
	// 		icon: Img,
	// 		title: "MATHEMATICS",
	// 		id: 10,
	// 	},
	// 	{
	// 		icon: Img,
	// 		title: "ENGLISH LANGUAGE",
	// 		id: 11,
	// 	},
	// 	{
	// 		icon: Img,
	// 		title: "COMPUTER SCIENCE",
	// 		id: 12,
	// 	},
	// ];

	const selectOpenModal = () => {
		setIsSelectModalOpen(true);
	};

	const closeSelectModal = () => {
		setIsSelectModalOpen(false);
	};

	let location = useLocation(),
		navigate = useNavigate(),
		{ subject } = useSelector(s => s);

	useEffect(() => {
		if (!location.state) navigate(-1);
	}, [location?.state, navigate]);

	return (
		<div>
			<div className=" w-[85%] m-auto">
				<Title title="MANAGE SUBJECTS" />
				<div className="ml-6 mt-6 mb-8 flex justify-between items-center">
					<h2 className="text-2xl">All Subjects</h2>
					<AddButton onClick={selectOpenModal} />
				</div>

				<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8 mt-8 ">
					{subject?.data?.docs
						?.filter(it => it?.examType?.includes(location?.state?._id))
						?.map((card, index) => (
							<CardListThree
								key={index}
								title={card?.title}
								icon={Img}
								to="/manage-exams/question"
								item={card}
							/>
						))}
				</div>

				{/* <div>
                <CardListThree cards={cards} />
            </div> */}
				<SelectModal
					isOpenSelectModal={isSelectModalOpen}
					closeSelectModal={closeSelectModal}
				/>
			</div>
		</div>
	);
};

export default ManageSubject;
