import React, { useState, useEffect } from "react";
import moment from "moment";

const LeaderBoardTable = ({ data }) => {
	return (
		<div className="w-full overflow-x-auto">
			<table className="min-w-full table-auto">
				<thead>
					<tr className="text-[#838080]">
						{/* <th className=" px-6 py-3 text-left text-[20px] font-semiblod uppercase tracking-wider">ID</th> */}
						<th className=" px-6 py-3 text-left text-md font-semiblod uppercase tracking-wider">
							Name
						</th>
						<th className=" px-6 py-3 text-left text-md font-semiblod uppercase tracking-wider">
							Date Created
						</th>
						<th className="px-6 py-3 text-left text-md font-semiblod uppercase tracking-wider">
							Phone Number
						</th>
						<th className="px-6 py-3 text-left text-md font-semiblod uppercase tracking-wider">
							Score
						</th>
						<th className="px-6 py-3 text-left text-md font-semiblod uppercase tracking-wider">
							Rank
						</th>
					</tr>
				</thead>
				<tbody>
					{data?.map((item, index) => (
						<tr
							key={index}
							className="shadow-md rounded text-[#1B1B1B]"
							// className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
						>
							{/* <td className="px-6 py-4 whitespace-nowrap">
                {item.id}
              </td> */}
							<td className="px-6 py-4 whitespace-nowrap">
								{item?.user?.firstName} {item?.user?.lastName}
							</td>
							<td className="px-6 py-4 whitespace-nowrap font-medium">
								{moment(item?.createdAt).format("DD/MM/YYYY")}
							</td>
							<td className="px-6 py-4 whitespace-nowrap font-medium">
								{item?.user?.telephone}
							</td>
							<td className="px-6 py-4 whitespace-nowrap">
								{Number(item?.totalScore || 0).toFixed(2)}
							</td>
							<td className="px-6 py-4 whitespace-nowrap">
								{/* {index + 1}
								{index + 1?.toString()?.endsWith("1")
									? "st"
									: index + 1?.toString()?.endsWith("2")
									? "nd"
									: index + 1?.toString()?.endsWith("3")
									? "rd"
									: "th"} */}
								<RankManager score={index} />
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default LeaderBoardTable;

export const RankManager = ({ score }) => {
	let [grader, setGrader] = useState("");

	let hostEndsWith = (host, ends) => {
		let value = false;
		value = ends?.some(element => {
			return host?.toString()?.endsWith(element?.toString());
		});
		return value;
	};
	useEffect(() => {
		if (score) {
			let finder = score + 1,
				finder2 = finder?.toString();
			if (hostEndsWith(finder2, ["11", "12", "13"])) {
				setGrader("th");
			} else if (finder2?.endsWith("1")) setGrader("st");
			else if (finder2?.endsWith("2")) setGrader("nd");
			else if (finder2?.endsWith("3")) setGrader("rd");
			else setGrader("th");
		} else if (score === 0) setGrader("st");
	}, [score]);

	return (
		<>
			{score + 1}
			{grader}
		</>
	);
};
