import React, {useState} from 'react'; 
import { LuPencil } from "react-icons/lu";
import { AiOutlineDelete } from "react-icons/ai";
// import Modal from '../modal/modal'; 
import Delete from '../modal/delete';
import { AddSubjectModal, AddYearModal } from "../modal/addModal";

const EditDeleteBtn = ({datum}) => {
    const [isModalOpen, setIsModalOpen] = useState(false); 
    const [isModalOpenDelete, setIsModalOpenDelete] = useState(false); 
    const [isHovered, setIsHovered] = useState(false); 
    // const [inputValue, setInputValue] = useState(''); 
  
    const openModal = () => {
      setIsModalOpen(true)
    }
  
    const closeModal = () => {
      setIsModalOpen(false)
    }
  
    const openModalDelete = () =>{
      setIsModalOpenDelete(true)
    }
  
    const closeModalDelete = () =>{
      setIsModalOpenDelete(false)
    }
  
    const handleMouseEnter = () => {
      setIsHovered(true);
    }
  
    const handleMouseLeave = () =>{
      setIsHovered(false);
    }
  return (
    <div>
        <div className='flex justify-center items-center gap-x-4'>
           <button 
             className='px-4 py-2 mr-2 border border-gray-600 rounded-md flex items-center gap-x-2 hover:bg-[#30388F] hover:text-[#fff] transition'
             onClick={openModal} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
             <LuPencil className={`icon  cursor-pointer  ${isHovered ? 'text-white' : 'text-[#30388F]'}`} 
             size={16}  />
             <span className='font-semibold'>Edit</span>
           </button>

           <button 
           className='px-4 py-2 mr-2 border border-gray-600 rounded-md flex items-center gap-x-2 hover:bg-red-500 hover:text-[#fff] transition'
           onClick={openModalDelete} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
             <AiOutlineDelete 
               className={`icon  cursor-pointer ${isHovered ? 'text-white' : 'text-red-500'}`} size={16} 
               onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}
             />
             <span className='font-semibold'>Delete</span>
           </button>
       </div>

       <AddSubjectModal  isOpenAddModal={isModalOpen} closeAddModal={closeModal} datum={datum} />
       <Delete isOpenDelete={isModalOpenDelete} closeModalDelete={closeModalDelete} datum={datum} type="Subject" />
    </div>
  )
}

export default EditDeleteBtn

export const EditDeleteYearBtn = ({ datum }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isModalOpenDelete, setIsModalOpenDelete] = useState(false);
	const [isHovered, setIsHovered] = useState(false);
	// const [inputValue, setInputValue] = useState('');

	const openModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	const openModalDelete = () => {
		setIsModalOpenDelete(true);
	};

	const closeModalDelete = () => {
		setIsModalOpenDelete(false);
	};

	const handleMouseEnter = () => {
		setIsHovered(true);
	};

	const handleMouseLeave = () => {
		setIsHovered(false);
	};
	return (
		<div>
			<div className="flex justify-center items-center gap-x-4">
				<button
					className="px-4 py-2 mr-2 border border-gray-600 rounded-md flex items-center gap-x-2 hover:bg-[#30388F] hover:text-[#fff] transition"
					onClick={openModal}
					onMouseEnter={handleMouseEnter}
					onMouseLeave={handleMouseLeave}>
					<LuPencil
						className={`icon  cursor-pointer  ${
							isHovered ? "text-white" : "text-[#30388F]"
						}`}
						size={16}
					/>
					<span className="font-semibold">Edit</span>
				</button>

				<button
					className="px-4 py-2 mr-2 border border-gray-600 rounded-md flex items-center gap-x-2 hover:bg-red-500 hover:text-[#fff] transition"
					onClick={openModalDelete}
					onMouseEnter={handleMouseEnter}
					onMouseLeave={handleMouseLeave}>
					<AiOutlineDelete
						className={`icon  cursor-pointer ${
							isHovered ? "text-white" : "text-red-500"
						}`}
						size={16}
						onMouseEnter={handleMouseEnter}
						onMouseLeave={handleMouseLeave}
					/>
					<span className="font-semibold">Delete</span>
				</button>
			</div>

			<AddYearModal
				isOpenAddModal={isModalOpen}
				closeAddModal={closeModal}
				datum={datum}
			/>
			<Delete
				isOpenDelete={isModalOpenDelete}
				closeModalDelete={closeModalDelete}
				datum={datum}
				type="Year"
			/>
		</div>
	);
};