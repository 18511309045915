import React from 'react';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import Button from "../button/button";

export const MainPaginate = ({ handlePageClick, pageCount }) => (
	<ReactPaginate
		breakLabel="..."
		nextLabel={
			<button className={`flex`}>
				Next
				<AiOutlineArrowRight className="icon ml-1" size={24} />
			</button>
		}
		onPageChange={handlePageClick}
		pageRangeDisplayed={5}
		pageCount={pageCount}
		previousLabel={
			<button className={`flex`}>
				<AiOutlineArrowLeft className="icon" size={24} />
				Previous
			</button>
		}
		renderOnZeroPageCount={null}
		className="flex items-center justify-center p-3  rounded-md mx-2"
		pageClassName="bg-white text-blue-500 px-4 py-2 text-sm font-medium"
		previousClassName="bg-blue-500 text-white px-4 py-2 rounded-md flex"
		nextClassName="px-4 py-2 rounded-md flex bg-blue-500 text-white"
		activeClassName="bg-[#2a71a8] text-white  rounded-md mx-2"
	/>
);

export const LoadMore = ({ handleLoadMore, next, loading }) => {
	return (
		<>
			{!next ? (
				""
			) : (
				<Button
					onClick={handleLoadMore}
					title={loading ? "Loading..." : "Load More"}
					loading={loading}
					css="mx-auto"
					type={"button"}
					buttonType={"primary"}
					width={"w"}
				/>
			)}
		</>
	);
};


const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const pageNumbers = [];

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <nav className="flex justify-center items-center space-x-4">
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className={`px-4 py-2 rounded-md flex ${
          currentPage === 1 ? 'bg-gray-300 text-gray-500 cursor-not-allowed' : 'bg-blue-500 text-white'
        }`}
      >
        <AiOutlineArrowLeft className='icon' size={24} />
        Previous
      </button>
      <ul className="inline-flex space-x-2">
        {pageNumbers.map((page) => (
          <li key={page}>
            <button
              className={`px-4 py-2 text-sm font-medium ${
                page === currentPage
                  ? 'bg-blue-500 text-white'
                  : 'bg-white text-blue-500'
              } rounded-md`}
              onClick={() => onPageChange(page)}
            >
              {page}
            </button>
          </li>
        ))}
      </ul>
      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className={`px-4 py-2 rounded-md flex ${
          currentPage === totalPages ? 'bg-gray-300 text-gray-500 cursor-not-allowed' : 'bg-blue-500 text-white'
        }`}
      >
        Next
      <AiOutlineArrowRight  className='icon ml-1' size={24}/>
      </button>
    </nav>
          
  );
};

export default Pagination;
