import React from 'react'

const VisitedAnswer = ({answer}) => {
    const visitedAnswer = answer * 2
  return (
    <div className='w-full'>
        <div className='flex justify-between items-center mb-2'>
            <h1 className='text-[#16.03] text-[#00284E] font-bold'>Visited Answers</h1>
            <p className='text-[12.47] text-[#00284E] font-bold'>25</p>
        </div>

        <div className='w-full bg-white'>
            <div className='w-[602.77px] h-[26.71px] grid justify-center items-center bg-[#FFF4FB] rounded-lg' style={{width: `${visitedAnswer}%`}}>
                <p className='text-[13px] font-bold'>
                    {visitedAnswer}
                </p>
            </div>
        </div>
    </div>
  )
}

export default VisitedAnswer
