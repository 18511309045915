import React, { useState, useEffect } from "react";
// import { useParams } from "react-router-dom";
import Title from "../../components/title/title";
import Card from "../../components/card/card";
import AddButton from "../../components/button/addbutton";
import AddModal from "../../components/modal/addModal";
import { LoadingLoader } from "../../components/button/button";
import Img from "../../assets/subjects.png";
import { useSelector, useDispatch } from "react-redux";
import { manageExamType } from "../../data/Reducers/ExamTypeReducer";

const ManageExams = () => {
	const [isAddModalOpen, setIsAddModalOpen] = useState(false);
	// const params = useParams();

	// const cardsData = [
	// 	{
	// 		icon: Img,
	// 		title: "WASSCE",
	// 		id: 1,
	// 	},
	// 	{
	// 		icon: Img,
	// 		title: "JAMB",
	// 		id: 2,
	// 	},
	// 	{
	// 		icon: Img,
	// 		title: "NECO",
	// 		id: 3,
	// 	},
	// 	{
	// 		icon: Img,
	// 		title: "NATEEB",
	// 		id: 4,
	// 	},
	// ];

	const openAddModal = () => {
		setIsAddModalOpen(true);
	};

	const closeAddModal = () => {
		setIsAddModalOpen(false);
	};

	let { examType } = useSelector(s => s),
		[data, setData] = useState(null),
		dispatch = useDispatch();

	useEffect(() => {
		setData(examType?.data);
	}, [examType?.data]);

	useEffect(() => {
		dispatch(manageExamType("get", null));
	}, [dispatch]);

	if (!data && !examType?.data) return <LoadingLoader />;

	return (
		<div>
			<div className="max-w-[95%] mx-auto">
				<Title title="MANAGE EXAMS" />
				<div className="mt-8 flex justify-between items-center">
					<h2 className="text-2xl">All Exams</h2>
					<div className="">
						<AddButton onClick={openAddModal} />
					</div>
				</div>

				<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8 mt-8 ">
					{data?.docs?.map((card, index) => (
						<Card
							key={index}
							title={card?.title}
							icon={card?.image?.url || Img}
							to="/manage-exams/subjects"
							item={card}
						/>
					))}
				</div>
			</div>
			<AddModal isOpenAddModal={isAddModalOpen} closeAddModal={closeAddModal} />
		</div>
	);
};

export default ManageExams;
