import React from 'react';

const DashBoardTable = ({ data, dummy }) => {
	return (
		<div className="w-full overflow-x-auto">
			<table className="min-w-full table-auto">
				<thead>
					<tr className="text-black font-medium text-medium">
						<th className=" px-6 py-3 text-left uppercase tracking-wider">
							Image
						</th>
						<th className=" px-6 py-3 text-left uppercase tracking-wider">
							Name
						</th>
						<th className="px-6 py-3 text-left uppercase tracking-wider">
							Email
						</th>
						<th className="px-6 py-3 text-left uppercase tracking-wider">
							Phone Number{" "}
						</th>
					</tr>
				</thead>
				<tbody>
					{data?.map((item, index) => (
						<tr
							key={index}
							className=" text-black text-sm"
							// className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
						>
							<td className="px-6 py-4 whitespace-nowrap">
								<img
									src={item.image?.url || dummy?.[index % data?.length]?.image}
									alt={item.lastName}
									className="h-12 w-12 object-cover rounded-full border border-[#FD5709]"
								/>
							</td>

							<td className="px-6 py-4 whitespace-nowrap">
								{item?.firstName} {item.lastName}
							</td>

							<td className="px-6 py-4 whitespace-nowrap font-medium">
								{item?.email}
							</td>
							<td className="px-6 py-4 whitespace-nowrap">{item?.telephone}</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default DashBoardTable;
