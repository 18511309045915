import React from "react";
import { HiPlus, HiEye } from "react-icons/hi";
// import AddModal from '../modal/addModal';

const AddButton = ({ onClick, type = "", title }) => {
	return (
		<div>
			<button
				className="flex items-center gap-x-2 bg-[#2C56AF] text-white py-2 mx-2 px-3 rounded hover:bg-blue-700 font-semibold"
				onClick={onClick}>
				{type === "view" ? (
					<HiEye className="icon" size={24} />
				) : (
					<HiPlus className="icon" size={24} />
				)}
				<span>{title || "Add"}</span>
			</button>
		</div>
	);
};

export default AddButton;
