import React, { useState, useEffect, useRef } from "react";
// import Success from "./success";
import { Buttons } from "../button/button";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { manageExamType } from "../../data/Reducers/ExamTypeReducer";
import ModalContainer from "../modal-container/modal-container";
import { manageSubject } from "../../data/Reducers/SubjectReducer";
import { manageGrade } from "../../data/Reducers/GradeReducer";
import { manageYear } from "../../data/Reducers/YearReducer";
import moment from "moment";
import { returnErrors } from "../../data/Reducers/ErrorReducer";
import axios from "axios";

const AddModal = ({ isOpenAddModal, closeAddModal, datum }) => {
	const [inputValue, setInputValue] = useState("");
	// const [isModalOpenSuccess, setIsModalOpenSuccess] = useState(false);

	// const openModalSuccess = () => {
	// 	setIsModalOpenSuccess(true);
	// };

	// const closeSuccess = () => {
	// 	setIsModalOpenSuccess(false);
	// 	closeAddModal();
	// };

	const handleInputChangeAddModal = ev => {
		setInputValue(ev.target.value);
	};

	let { examType } = useSelector(s => s),
		[loading, setLoading] = useState(false),
		dispatch = useDispatch(),
		[logo, setLogo] = useState(null),
		fileInputRef = useRef();

	const handleSubmit = async e => {
		e?.preventDefault();
		if (!inputValue) return toast.info("Exam Type is required");
		setLoading(true);
		let newState = {
			title: inputValue,
			_id: datum?._id || null,
		};
		if (logo) {
			try {
				let post = new FormData();
				post.append(`file`, logo);

				let resImg = await axios.post(`/api/v1/file`, post, {
					headers: {
						"Content-Type": "multipart/form-data",
					},
				});

				newState.image = resImg?.data?.data?.[0];
			} catch (err) {
				if (err) console.log({ error: err.response?.data, err });
				if (err?.response?.status === 429) toast.error(err?.response?.data);

				let error = err.response?.data?.error;
				if (error) {
					setLoading(false);
					return dispatch(
						returnErrors({ error, status: err?.response?.status })
					);
				} else {
					setLoading(false);
					return toast.error(err?.response?.data?.message);
				}
			}
		}
		await dispatch(manageExamType(datum ? "put" : "post", newState));
		setLoading(false);
	};

	let reset = () => {
		setInputValue("");
	};

	useEffect(() => {
		if (["added", "updated"]?.includes(examType?.status)) {
			reset();
			// openModalSuccess();
			closeAddModal();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [examType?.status]);

	useEffect(() => {
		if (datum) setInputValue(datum?.title);
	}, [datum]);

	if (!isOpenAddModal) return null;

	let handleChangeImage = e => {
		const file = e.target.files[0];
		let err = "";

		if (!file) return (err = `File, ${file?.name} does not exist`);
		if (!file.type.includes("image"))
			return (err = `File, ${file?.name} format not supported`);

		if (err) {
			return toast.error(err);
		} else {
			setLogo(file);
		}
	};

	return (
		<>
			{/* <div
				className="fixed top-0 left-0 z-50 w-full h-full bg-[rgba(0,0,0,0.2)] flex flex-col justify-center items-center p-4"
				onClick={closeAddModal}>
				<div className="relative w-1/3 bg-white flex flex-col h-80">
					<h1 className="text-[24px] font-bold mt-8 text-center">
						{datum ? "EDIT" : "ADD"} EXAM TYPE
					</h1>
					<div className="my-10 flex flex-col mx-8">
						<label htmlFor="input" className=" font-semibold text-[20px]">
							Exam Name
						</label>
						<input
							type="text"
							id="input"
							className="border rounded w-full py-2 px-3 text-gray-700 my-4"
							value={inputValue}
							onChange={handleInputChangeAddModal}
						/>
						<div className="flex justify-center items-center my-6">
							
							<Buttons
								onClick={handleSubmit}
								loading={loading}
								title={"Save"}
								width={
									"bg-[#30388F] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition-all duration-300 focus:outline-none focus:shadow-outline cursor-pointer"
								}
							/>
							<div className="mt-8">
								<Success
									isOpenSuccess={isModalOpenSuccess}
									closeSuccess={closeSuccess}
								/>
							</div>
						</div>
					</div>
				</div>
			</div> */}
			<ModalContainer
				title={datum ? `Edit Exam Type` : "Add Exam Type"}
				show={isOpenAddModal}
				close={closeAddModal}>
				<div className="px-6 py-4 overflow-y-auto ">
					{/* Add your content here */}
					<div className="popmodal__cover">
						<div className="popmodal__content ">
							<form>
								<div className="flex flex-col items-center gap-4 mt-8">
									<img
										src={
											logo
												? URL.createObjectURL(logo)
												: datum?.image?.url || null
										}
										alt=""
										className="h-36 w-36 rounded-full border"
									/>
									<div className="flex gap-6">
										{logo && (
											<button
												type="button"
												onClick={() => setLogo(null)}
												className="h-8 w-24 bg-main rounded-xl text-white barlow text-base font-normal">
												Remove
											</button>
										)}
										<div>
											<input
												ref={fileInputRef}
												type="file"
												accept="image/*"
												style={{ display: "none" }}
												onChange={handleChangeImage}
												name="image"
											/>
											<button
												type="button"
												className="h-8 w-24 bg-main rounded-xl text-white barlow text-base font-normal"
												onClick={() => fileInputRef.current.click()}>
												Upload
											</button>
										</div>
									</div>
								</div>
								<label htmlFor="input" className=" font-semibold text-[20px]">
									Exam Name
								</label>
								<input
									type="text"
									id="input"
									className="border rounded w-full py-2 px-3 text-gray-700 my-4 shadow"
									value={inputValue}
									onChange={handleInputChangeAddModal}
								/>
								<div className="mt-8 flex  justify-center">
									<Buttons
										// buttonType={"primary"}
										title={datum ? "Update" : "Save"}
										type="submit"
										width={
											"w-fit bg-[#30388F] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition-all duration-300 focus:outline-none focus:shadow-outline cursor-pointer"
										}
										loading={loading}
										onClick={handleSubmit}
									/>
								</div>
							</form>
						</div>
					</div>
				</div>
			</ModalContainer>
			{/* <div className="mt-8">
				<Success
					isOpenSuccess={isModalOpenSuccess}
					closeSuccess={closeSuccess}
				/>
			</div> */}
		</>
	);
};

export default AddModal;

export const AddSubjectModal = ({ isOpenAddModal, closeAddModal, datum }) => {
	const [inputValue, setInputValue] = useState("");
	// const [isModalOpenSuccess, setIsModalOpenSuccess] = useState(false);

	// const openModalSuccess = () => {
	// 	setIsModalOpenSuccess(true);
	// };

	// const closeSuccess = () => {
	// 	setIsModalOpenSuccess(false);
	// 	closeAddModal();
	// };

	const handleInputChangeAddModal = ev => {
		setInputValue(ev.target.value);
	};

	let { subject } = useSelector(s => s),
		[loading, setLoading] = useState(false),
		dispatch = useDispatch();

	const handleSubmit = async e => {
		e?.preventDefault();
		if (!inputValue) return toast.info("Subject is required");
		setLoading(true);
		await dispatch(
			manageSubject(datum ? "put" : "post", {
				title: inputValue,
				_id: datum?._id || null,
			})
		);
		setLoading(false);
	};

	let reset = () => {
		setInputValue("");
	};

	useEffect(() => {
		if (["added", "updated"]?.includes(subject?.status)) {
			reset();
			// openModalSuccess();
			closeAddModal();
		}
		// return ()=> setIsModalOpenSuccess(false)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [subject?.status]);

	useEffect(() => {
		if (datum) setInputValue(datum?.title);
	}, [datum]);

	if (!isOpenAddModal) return null;

	return (
		<>
			{/* <div
				className="fixed top-0 left-0 z-50 w-full h-full bg-[rgba(0,0,0,0.2)] flex flex-col justify-center items-center p-4"
				onClick={closeAddModal}>
				<div className="relative w-1/3 bg-white flex flex-col h-80">
					<h1 className="text-[24px] font-bold mt-8 text-center">
						{datum ? "EDIT" : "ADD"} EXAM TYPE
					</h1>
					<div className="my-10 flex flex-col mx-8">
						<label htmlFor="input" className=" font-semibold text-[20px]">
							Exam Name
						</label>
						<input
							type="text"
							id="input"
							className="border rounded w-full py-2 px-3 text-gray-700 my-4"
							value={inputValue}
							onChange={handleInputChangeAddModal}
						/>
						<div className="flex justify-center items-center my-6">
							
							<Buttons
								onClick={handleSubmit}
								loading={loading}
								title={"Save"}
								width={
									"bg-[#30388F] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition-all duration-300 focus:outline-none focus:shadow-outline cursor-pointer"
								}
							/>
							<div className="mt-8">
								<Success
									isOpenSuccess={isModalOpenSuccess}
									closeSuccess={closeSuccess}
								/>
							</div>
						</div>
					</div>
				</div>
			</div> */}
			<ModalContainer
				title={datum ? `Edit Subject` : "Add Subject"}
				show={isOpenAddModal}
				close={closeAddModal}>
				<div className="px-6 py-4 overflow-y-auto ">
					{/* Add your content here */}
					<div className="popmodal__cover">
						<div className="popmodal__content ">
							<form>
								<label htmlFor="input" className=" font-semibold text-[20px]">
									Subject
								</label>
								<input
									type="text"
									id="input"
									className="border rounded w-full py-2 px-3 text-gray-700 my-4 shadow"
									value={inputValue}
									onChange={handleInputChangeAddModal}
								/>
								<div className="mt-8 flex  justify-center">
									<Buttons
										// buttonType={"primary"}
										title={datum ? "Update" : "Save"}
										type="submit"
										width={
											"w-fit bg-[#30388F] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition-all duration-300 focus:outline-none focus:shadow-outline cursor-pointer"
										}
										loading={loading}
										onClick={handleSubmit}
									/>
								</div>
							</form>
						</div>
					</div>
				</div>
			</ModalContainer>
			{/* <div className="mt-8">
				<Success
					isOpenSuccess={isModalOpenSuccess}
					closeSuccess={closeSuccess}
				/>
			</div> */}
		</>
	);
};

export const AddGradeModal = ({
	isOpenAddModal,
	closeAddModal,
	datum,
	setDatum,
}) => {
	const [inputValue, setInputValue] = useState(""),
		init = {},
		[state, setState] = useState(init),
		textChange = e => {
			let { name, value } = e?.target;
			setState({ ...state, [name]: value });
		};
	// const [isModalOpenSuccess, setIsModalOpenSuccess] = useState(false);

	// const openModalSuccess = () => {
	// 	setIsModalOpenSuccess(true);
	// };

	// const closeSuccess = () => {
	// 	setIsModalOpenSuccess(false);
	// 	closeAddModal();
	// };

	const handleInputChangeAddModal = ev => {
		setInputValue(ev.target.value);
	};

	let { grade } = useSelector(s => s),
		[loading, setLoading] = useState(false),
		dispatch = useDispatch();

	const handleSubmit = async e => {
		e?.preventDefault();
		if (!inputValue) return toast.info("Grade is required");
		setLoading(true);
		await dispatch(
			manageGrade(datum ? "put" : "post", {
				grade: inputValue,
				minimumScore: state?.minimumScore,
				maximumScore: state?.maximumScore,
				comment: state?.comment,
				_id: datum?._id || null,
			})
		);
		setLoading(false);
	};

	let reset = () => {
		setInputValue("");
		if (datum) setDatum(null);
		setState(init);
	};

	useEffect(() => {
		if (["added", "updated"]?.includes(grade?.status)) {
			reset();
			// openModalSuccess();
			closeAddModal();
		}
		// return ()=> setIsModalOpenSuccess(false)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [grade?.status]);

	useEffect(() => {
		if (datum) {
			setInputValue(datum?.grade);
			setState({ ...datum });
		}
	}, [datum]);

	if (!isOpenAddModal) return null;

	return (
		<>
			{/* <div
				className="fixed top-0 left-0 z-50 w-full h-full bg-[rgba(0,0,0,0.2)] flex flex-col justify-center items-center p-4"
				onClick={closeAddModal}>
				<div className="relative w-1/3 bg-white flex flex-col h-80">
					<h1 className="text-[24px] font-bold mt-8 text-center">
						{datum ? "EDIT" : "ADD"} EXAM TYPE
					</h1>
					<div className="my-10 flex flex-col mx-8">
						<label htmlFor="input" className=" font-semibold text-[20px]">
							Exam Name
						</label>
						<input
							type="text"
							id="input"
							className="border rounded w-full py-2 px-3 text-gray-700 my-4"
							value={inputValue}
							onChange={handleInputChangeAddModal}
						/>
						<div className="flex justify-center items-center my-6">
							
							<Buttons
								onClick={handleSubmit}
								loading={loading}
								title={"Save"}
								width={
									"bg-[#30388F] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition-all duration-300 focus:outline-none focus:shadow-outline cursor-pointer"
								}
							/>
							<div className="mt-8">
								<Success
									isOpenSuccess={isModalOpenSuccess}
									closeSuccess={closeSuccess}
								/>
							</div>
						</div>
					</div>
				</div>
			</div> */}
			<ModalContainer
				title={datum ? `Edit Grade` : "Add Grade"}
				show={isOpenAddModal}
				close={closeAddModal}>
				<div className="px-6 py-4 overflow-y-auto ">
					{/* Add your content here */}
					<div className="popmodal__cover">
						<div className="popmodal__content ">
							<form>
								<label htmlFor="input" className=" font-semibold text-[20px]">
									Grade
								</label>
								<input
									type="text"
									id="input"
									className="border rounded w-full py-2 px-3 text-gray-700 my-4 shadow"
									value={inputValue}
									onChange={handleInputChangeAddModal}
								/>
								<label htmlFor="input" className=" font-semibold text-[20px]">
									Minimum Score
								</label>
								<input
									type="tel"
									id="input"
									className="border rounded w-full py-2 px-3 text-gray-700 my-4 shadow"
									value={state?.minimumScore}
									onChange={textChange}
									name="minimumScore"
									min={0}
								/>
								<label htmlFor="input" className=" font-semibold text-[20px]">
									Maximum Score
								</label>
								<input
									type="tel"
									id="input"
									className="border rounded w-full py-2 px-3 text-gray-700 my-4 shadow"
									value={state?.maximumScore}
									onChange={textChange}
									name="maximumScore"
									min={0}
								/>
								<label htmlFor="input" className=" font-semibold text-[20px]">
									Remark
								</label>
								<input
									type="text"
									id="input"
									className="border rounded w-full py-2 px-3 text-gray-700 my-4 shadow"
									value={state?.comment}
									onChange={textChange}
									name="comment"
								/>
								<div className="mt-8 flex  justify-center">
									<Buttons
										// buttonType={"primary"}
										title={datum ? "Update" : "Save"}
										type="submit"
										width={
											"w-fit bg-[#30388F] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition-all duration-300 focus:outline-none focus:shadow-outline cursor-pointer"
										}
										loading={loading}
										onClick={handleSubmit}
									/>
								</div>
							</form>
						</div>
					</div>
				</div>
			</ModalContainer>
			{/* <div className="mt-8">
				<Success
					isOpenSuccess={isModalOpenSuccess}
					closeSuccess={closeSuccess}
				/>
			</div> */}
		</>
	);
};

export const AddYearModal = ({ isOpenAddModal, closeAddModal, datum }) => {
	const [inputValue, setInputValue] = useState("");
	// const [isModalOpenSuccess, setIsModalOpenSuccess] = useState(false);

	// const openModalSuccess = () => {
	// 	setIsModalOpenSuccess(true);
	// };

	// const closeSuccess = () => {
	// 	setIsModalOpenSuccess(false);
	// 	closeAddModal();
	// };

	const handleInputChangeAddModal = ev => {
		setInputValue(ev.target.value);
	};

	let { year } = useSelector(s => s),
		[loading, setLoading] = useState(false),
		dispatch = useDispatch();

	const handleSubmit = async e => {
		e?.preventDefault();
		if (!inputValue) return toast.info("Year is required");
		setLoading(true);
		await dispatch(
			manageYear(datum ? "put" : "post", {
				title: inputValue,
				_id: datum?._id || null,
			})
		);
		setLoading(false);
	};

	let reset = () => {
		setInputValue("");
	};

	useEffect(() => {
		if (["added", "updated"]?.includes(year?.status)) {
			reset();
			// openModalSuccess();
			closeAddModal();
		}
		// return ()=> setIsModalOpenSuccess(false)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [year?.status]);

	useEffect(() => {
		if (datum) setInputValue(datum?.title);
	}, [datum]);

	if (!isOpenAddModal) return null;

	return (
		<>
			{/* <div
				className="fixed top-0 left-0 z-50 w-full h-full bg-[rgba(0,0,0,0.2)] flex flex-col justify-center items-center p-4"
				onClick={closeAddModal}>
				<div className="relative w-1/3 bg-white flex flex-col h-80">
					<h1 className="text-[24px] font-bold mt-8 text-center">
						{datum ? "EDIT" : "ADD"} EXAM TYPE
					</h1>
					<div className="my-10 flex flex-col mx-8">
						<label htmlFor="input" className=" font-semibold text-[20px]">
							Exam Name
						</label>
						<input
							type="text"
							id="input"
							className="border rounded w-full py-2 px-3 text-gray-700 my-4"
							value={inputValue}
							onChange={handleInputChangeAddModal}
						/>
						<div className="flex justify-center items-center my-6">
							
							<Buttons
								onClick={handleSubmit}
								loading={loading}
								title={"Save"}
								width={
									"bg-[#30388F] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition-all duration-300 focus:outline-none focus:shadow-outline cursor-pointer"
								}
							/>
							<div className="mt-8">
								<Success
									isOpenSuccess={isModalOpenSuccess}
									closeSuccess={closeSuccess}
								/>
							</div>
						</div>
					</div>
				</div>
			</div> */}
			<ModalContainer
				title={datum ? `Edit Year` : "Add Year"}
				show={isOpenAddModal}
				close={closeAddModal}>
				<div className="px-6 py-4 overflow-y-auto ">
					{/* Add your content here */}
					<div className="popmodal__cover">
						<div className="popmodal__content ">
							<form>
								<label htmlFor="input" className=" font-semibold text-[20px]">
									Year
								</label>
								<input
									type="number"
									id="input"
									className="border rounded w-full py-2 px-3 text-gray-700 my-4 shadow"
									value={inputValue}
									onChange={handleInputChangeAddModal}
									max={Number(moment().format("YYYY"))}
								/>
								<div className="mt-8 flex  justify-center">
									<Buttons
										// buttonType={"primary"}
										title={datum ? "Update" : "Save"}
										type="submit"
										width={
											"w-fit bg-[#30388F] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition-all duration-300 focus:outline-none focus:shadow-outline cursor-pointer"
										}
										loading={loading}
										onClick={handleSubmit}
									/>
								</div>
							</form>
						</div>
					</div>
				</div>
			</ModalContainer>
			{/* <div className="mt-8">
				<Success
					isOpenSuccess={isModalOpenSuccess}
					closeSuccess={closeSuccess}
				/>
			</div> */}
		</>
	);
};
