import React, { useState, useEffect } from "react";
import Title from "../../components/title/title";
import { useSelector, useDispatch } from "react-redux";
import { LoadingLoader } from "../../components/button/button";
import { manageGrade } from "../../data/Reducers/GradeReducer";
import AddButton from "../../components/button/addbutton";
import { AddGradeModal } from "../../components/modal/addModal";
import { LuPencil } from "react-icons/lu";

const Grade = () => {
	let { grade } = useSelector(s => s),
		[data, setData] = useState(null),
		dispatch = useDispatch(),
		[isOpen, setIsOpen] = useState(false),
		[datum, setDatum] = useState(null);

	useEffect(() => {
		setData(grade?.data);
	}, [grade?.data]);

	useEffect(() => {
		dispatch(manageGrade("get", null));
	}, [dispatch]);

	if (!data && !grade?.data) return <LoadingLoader />;

	return (
		<div className="w-[85%] m-auto">
			<div>
				<Title
					title="Grade"
					// description="View Leaderboard performance"
				/>
				<div className="mt-8 flex justify-between items-center">
					<h2 className="text-2xl">All Exams</h2>
					<div className="">
						<AddButton onClick={() => setIsOpen(!isOpen)} />
					</div>
				</div>
			</div>
			<div className="">
				<div className="bg-white shadow-lg ml-6 mt-10 p-4">
					<div className="w-full overflow-x-auto">
						<table className="min-w-full table-auto">
							<thead>
								<tr className="text-[#838080]">
									<th className=" px-6 py-3 text-left text-md font-semiblod uppercase tracking-wider">
										S/N
									</th>
									<th className=" px-6 py-3 text-left text-md font-semiblod uppercase tracking-wider">
										Grade
									</th>
									<th className=" px-6 py-3 text-left text-md font-semiblod uppercase tracking-wider">
										Minimum Score
									</th>
									<th className="px-6 py-3 text-left text-md font-semiblod uppercase tracking-wider">
										Maximum Score
									</th>
									<th className="px-6 py-3 text-left text-md font-semiblod uppercase tracking-wider">
										Remark
									</th>
									<th className="px-6 py-3 text-left text-md font-semiblod uppercase tracking-wider">
										Action
									</th>
								</tr>
							</thead>
							<tbody>
								{data?.docs?.map((item, index) => (
									<tr
										key={index}
										className="shadow-md rounded text-[#1B1B1B]"
										// className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
									>
										<td className="px-6 py-4 whitespace-nowrap">{index + 1}</td>
										<td className="px-6 py-4 whitespace-nowrap">
											{item?.grade}
										</td>
										<td className="px-6 py-4 whitespace-nowrap font-medium">
											{item.minimumScore}
										</td>
										<td className="px-6 py-4 whitespace-nowrap font-medium">
											{item?.maximumScore}
										</td>
										<td className="px-6 py-4 whitespace-nowrap">
											{item.comment}
										</td>
										<td className="px-6 py-4 whitespace-nowrap">
											<LuPencil
												className="icon text-gray-400 cursor-pointer hover:text-blue-500"
												size={20}
												onClick={() => {
													setIsOpen(true);
													setDatum(item);
												}}
											/>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<AddGradeModal
				isOpenAddModal={isOpen}
				closeAddModal={() => setIsOpen(false)}
				isOpenSelectModal={isOpen}
				datum={datum}
				setDatum={setDatum}
			/>
		</div>
	);
};

export default Grade;
