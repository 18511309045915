import React, { useEffect, useState } from "react";
import Title from "../../components/title/title";
import AddButton from "../../components/button/addbutton";
import ManageQuestion, {
	QuestionsOption,
} from "../../components/ManageQuestion/ManageQuestion";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { returnErrors } from "../../data/Reducers/ErrorReducer";
import { LoadingLoader } from "../../components/button/button";
import { Update } from "../../components/modal/delete";

const Managequestion = () => {
	const [selectOption, setSelectOption] = useState("");

	let location = useLocation(),
		dispatch = useDispatch(),
		[loading, setLoading] = useState(false),
		[questions, setQuestions] = useState(null),
		[optionsEdit, setOptionsEdit] = useState(null),
		[updater, setUpdater] = useState("");

	const handleSelectChange = ev => {
		setSelectOption(ev.target.value);
	};

	let handleSubmit = async () => {
		setLoading(true);
		try {
			let res = await axios.get(
				`/api/v1/exam?examType=${location?.state?.examType}&subject=${
					location?.state?.subject
				}&year=${selectOption || location?.state?.year}`,
				{
					data: {
						...location?.state,
						year: selectOption || location?.state?.year,
					},
				}
			);
			// console.log({ resp: res?.data });
			toast.success(res?.data?.message);
			setQuestions(res?.data?.data?.questions);
			setOptionsEdit(null);
			setUpdater(null);
		} catch (err) {
			if (err?.response?.status === 429 || err?.response?.status === 405)
				toast.error(err?.response?.data ? err?.response?.data : err?.message);
			console.log({ err });
			let error = err.response?.data?.error;
			if (error && error?.length > 1) {
				dispatch(returnErrors({ error, status: err?.response?.status }));
			} else {
				toast.error(err?.response?.data?.message);
			}
			setQuestions(null);
		}
		setLoading(false);
	};

	let deleteQuestion = async (ques, closeModalDelete, type) => {
		try {
			if (!ques?._id) return;
			let res = await axios.delete(
				`/api/v1/exam/${type || "question"}?id=${ques?._id}`
			);
			console.log({ resp: res }, "remove");
			toast.success(res?.data?.message);
			setQuestions(res?.data?.data?.questions);
			if (optionsEdit) {
				let findEdit = res?.data?.data?.questions?.find(
					it => it?._id === optionsEdit?._id
				);
				if (findEdit) setOptionsEdit(findEdit);
				else setOptionsEdit(null);
			}
			// setOptionsEdit(null)
			closeModalDelete();
		} catch (err) {
			if (err?.response?.status === 429 || err?.response?.status === 405)
				toast.error(err?.response?.data ? err?.response?.data : err?.message);
			console.log({ err });
			let error = err.response?.data?.error;
			if (error && error?.length > 1) {
				dispatch(returnErrors({ error, status: err?.response?.status }));
			} else {
				toast.error(err?.response?.data?.message);
			}
		}
	};

	let updateQuestion = async (ques, closeModalDelete, type, stat) => {
		try {
			if (!ques?._id) return;
			console.log({ stat });
			let element = stat;

			if (element?.[`${type}file`]) {
				try {
					let post = new FormData();
					post.append(`file`, element?.[`${type}file`]);

					let resImg = await axios.post(`/api/v1/file`, post, {
						headers: {
							"Content-Type": "multipart/form-data",
						},
					});

					element.file = resImg?.data?.data?.[0];
				} catch (err) {
					if (err) console.log({ error: err.response?.data, err });
					if (err?.response?.status === 429) toast.error(err?.response?.data);

					let error = err.response?.data?.error;
					if (error) {
						setLoading(false);
						return dispatch(
							returnErrors({ error, status: err?.response?.status })
						);
					} else {
						setLoading(false);
						return toast.error(err?.response?.data?.message);
					}
				}
			}

			let res = await axios.put(
				`/api/v1/exam/${type || "question"}?${type || "question"}Id=${
					ques?._id
				}`,
				{ ...element }
			);
			console.log({ resp: res }, "update");
			toast.success(res?.data?.message);
			setQuestions(res?.data?.data?.questions);
			if (optionsEdit) {
				let findEdit = res?.data?.data?.questions?.find(
					it => it?._id === optionsEdit?._id
				);
				if (findEdit) setOptionsEdit(findEdit);
				else setOptionsEdit(null);
			}
			// setOptionsEdit(null)
			closeModalDelete();
		} catch (err) {
			if (err?.response?.status === 429 || err?.response?.status === 405)
				toast.error(err?.response?.data ? err?.response?.data : err?.message);
			console.log({ err });
			let error = err.response?.data?.error;
			if (error && error?.length > 1) {
				dispatch(returnErrors({ error, status: err?.response?.status }));
			} else {
				toast.error(err?.response?.data?.message);
			}
		}
	};

	useEffect(() => {
		if (selectOption && location?.state) handleSubmit();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectOption, location?.state]);

	let navigate = useNavigate(),
		{ year, examType, subject } = useSelector(s => s);
	// console.log({ location, questions });
	console.log({ optionsEdit });
	if (loading && !questions) return <LoadingLoader />;

	return (
		<div className="w-[90%] m-auto">
			<div className="">
				<Title title="MANAGE EXAMS QUESTIONS" />
				<h1 className="text-[20px]  font-bold">All Questions</h1>
			</div>

			<div className=" mt-6">
				<div className="flex justify-between flex-col sm:flex-row md:flex-col lg:flex-row">
					<div>
						<div className="flex">
							<h1 className="text-[24px] font-bold">
								ADD QUESTION, (Pick a Year)
							</h1>
							<select
								className="w-10 border-0 outline-none focus:outline-none"
								value={selectOption}
								onChange={handleSelectChange}>
								<option value=""></option>
								<option value="" className="text-[20px] font-bold">
									YEAR LIST
								</option>
								{year?.data?.docs?.map((item, i) => (
									<option value={item?._id}>{item?.title}</option>
								))}
							</select>
						</div>
						<div>
							<h1 className="text-xl font-bold uppercase">
								{location?.state?.examType &&
									examType?.data?.docs?.find(
										it => it?._id === location?.state?.examType
									)?.title}{" "}
								{location?.state?.subject &&
									subject?.data?.docs?.find(
										it => it?._id === location?.state?.subject
									)?.title}{" "}
								{year?.data?.docs?.find(it => it?._id === selectOption)?.title}
							</h1>
						</div>
					</div>
					{/* Add Button */}
					{!questions && selectOption && (
						<div>
							<AddButton
								onClick={() => {
									if (!selectOption) return;
									navigate("/manage-exams/edit", {
										state: { ...location?.state, year: selectOption },
									});
								}}
							/>
						</div>
					)}
				</div>
			</div>
			<div>
				{!optionsEdit ? (
					questions?.map((card, index) => (
						<ManageQuestion
							key={index}
							question={card?.question}
							item={card}
							deleteFunc={deleteQuestion}
							updateFunc={updateQuestion}
							index={index}
							setOptionsEdit={setOptionsEdit}
							// to="../manage-exams/edit"
						/>
					))
				) : (
					<>
						<ManageQuestion
							question={optionsEdit?.question}
							item={optionsEdit}
							deleteFunc={deleteQuestion}
							updateFunc={updateQuestion}
							// to="../manage-exams/edit"
							setUpdater={setUpdater}
							updater={updater}
						/>
						{optionsEdit?.options?.map((card, index) => (
							<QuestionsOption
								key={index}
								question={card?.option}
								item={card}
								deleteFunc={deleteQuestion}
								updateFunc={updateQuestion}
								index={index}
								setUpdater={setUpdater}
								updater={updater}
								mainQuestion={optionsEdit}
								// to="../manage-exams/edit"
							/>
						))}
					</>
				)}
			</div>
			{/* <AddModal isOpenAddModal={isAddModalOpen} closeAddModal={closeAddModal} /> */}
			<Update
				isOpenUpdate={updater ? true : false}
				closeModalUpdate={() => setUpdater(null)}
				datum={updater?.item}
				type={updater?.type === "option" ? "Option" : "Question"}
				updateFunc={state =>
					updateQuestion(
						updater?.item,
						() => setUpdater(null),
						updater?.type,
						state
					)
				}
			/>
		</div>
	);
};

export default Managequestion;
