import React, { useState, useEffect, useRef } from "react";
// import Success from "./success";
import { useSelector, useDispatch } from "react-redux";
import { manageExamType } from "../../data/Reducers/ExamTypeReducer";
import { toast } from "react-toastify";
import ModalContainer from "../modal-container/modal-container";
import { Buttons } from "../button/button";
import { getSubject, manageSubject } from "../../data/Reducers/SubjectReducer";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { returnErrors } from "../../data/Reducers/ErrorReducer";
import { manageYear } from "../../data/Reducers/YearReducer";

const Delete = ({
	isOpenDelete,
	closeModalDelete,
	datum,
	type = "Exam Type",
	subType = "examType",
	deleteFunc = null,
}) => {
	// const [isModalOpenSuccess, setIsModalOpenSuccess] = useState(false);

	// const openModalSuccess = () => {
	// 	setIsModalOpenSuccess(true);
	// };

	// const closeSuccess = () => {
	// 	setIsModalOpenSuccess(false);
	// 	closeModalDelete();
	// };

	let { examType, subject } = useSelector(s => s),
		[loading, setLoading] = useState(false),
		dispatch = useDispatch(),
		location = useLocation();

	const handleSubmit = async e => {
		e?.preventDefault();
		if (!datum) return toast.info(`${type} is required`);
		setLoading(true);
		if (type === "Exam Type") await dispatch(manageExamType("delete", datum));
		if (type === "Subject") await dispatch(manageSubject("delete", datum));
		if (type === "Year") await dispatch(manageYear("delete", datum));
		if (["Question", "Option"]?.includes(type)) await deleteFunc();
		if (type === "Subject Exam Type")
			try {
				let data = {
					type: subType,
				};
				if (subType === "examType")
					data.subject = location?.state?.subject || datum?._id;
				data.examType = location?.state?.examType || location?.state?._id;
				let res = await axios.delete(`/api/v1/exam`, { data });
				console.log({ resp: res }, "remove");
				toast.success(res?.data?.message);
				if (subType === "examType") dispatch(getSubject(res?.data));
				closeModalDelete();
			} catch (err) {
				if (err?.response?.status === 429 || err?.response?.status === 405)
					toast.error(err?.response?.data ? err?.response?.data : err?.message);
				console.log({ err });
				let error = err.response?.data?.error;
				if (error && error?.length > 1) {
					dispatch(returnErrors({ error, status: err?.response?.status }));
				} else {
					toast.error(err?.response?.data?.message);
				}
			}

		setLoading(false);
	};

	useEffect(() => {
		if (["deleted"]?.includes(examType?.status)) {
			// openModalSuccess();
			closeModalDelete();
		}
		if (["deleted"]?.includes(subject?.status)) {
			// openModalSuccess();
			closeModalDelete();
		}
		// return () => setIsModalOpenSuccess(false);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [examType?.status, subject?.status]);

	if (!isOpenDelete) return null;

	return (
		<>
			{/* <div className="fixed top-0 left-0 z-50 w-full h-full bg-[rgba(0,0,0,0.1)] flex flex-col items-center justifty-center">
				<div className="relative w-1/3 bg-white shadow-lg m-auto flex flex-col justify-center items-center p-4">
					<div className="flex flex-col justify-center items-center">
						<img
							src={require("../../assets/delete-RgJTzXWDsF.png")}
							alt="successful"
							className="mt-8"
						/>
						<h3 className="mt-12 text-[24px] text-center">
							Are you sure you want to <br /> delete this{" "}
							<span className="font-bold">Item Type?</span>
						</h3>
					</div>

					<div className="flex justify-center items-center my-8">
						<button
							className="px-4 py-2 mr-2 border border-gray-600 text-[#30388F] rounded-md transition-all duration-300 hover:bg-[#30388F] hover:text-white focus:outline-none focus:shadow-outline cursor-pointer"
							onClick={closeModalDelete}>
							Cancel
						</button>
						<button
							className="bg-[#F32424] hover:bg-orange-700 text-white font-bold py-2 px-4 rounded transition-all duration-300 focus:outline-none focus:shadow-outline cursor-pointer"
							onClick={openModalSuccess}>
							Delete
						</button>
					</div>
				</div>
			</div> */}
			<ModalContainer
				title={`Delete ${type}`}
				width={"max-w-sm"}
				show={isOpenDelete ? true : false}
				close={closeModalDelete}>
				<div className="mx-20">
					<form className="space-y-4">
						<div className="my-auto w-100">
							<div className="flex flex-col justify-center items-center">
								<img
									src={require("../../assets/delete-RgJTzXWDsF.png")}
									alt="successful"
									className="mt-8"
								/>
								<h3 className="mt-12 text-[24px] text-center">
									Are you sure you want to <br /> delete{" "}
									<span className="font-bold">
										{datum?.title || datum?.option || datum?.question}?
									</span>
								</h3>
							</div>
							<div className="pt-4 flex">
								<Buttons
									// buttonType={"primary"}
									title={"Yes"}
									type="button"
									width={
										"w-fit me-2 bg-[#F32424] hover:bg-orange-700 text-white font-bold py-2 px-4 rounded transition-all duration-300 focus:outline-none focus:shadow-outline cursor-pointer"
									}
									loading={loading}
									onClick={handleSubmit}
								/>
								<Buttons
									// buttonType={"secondary"}
									title={"No"}
									type="button"
									width={
										"w-fit ms-2 px-4 py-2 mr-2 border border-gray-600 text-[#30388F] rounded-md transition-all duration-300 hover:bg-[#30388F] hover:text-white focus:outline-none focus:shadow-outline cursor-pointer"
									}
									onClick={closeModalDelete}
								/>
							</div>
						</div>
					</form>
				</div>
			</ModalContainer>
			{/* <Success isOpenSuccess={isModalOpenSuccess} closeSuccess={closeSuccess} /> */}
		</>
	);
};

export default Delete;

export const Update = ({
	isOpenUpdate,
	closeModalUpdate,
	datum,
	type = "Question",
	updateFunc = null,
}) => {
	let [loading, setLoading] = useState(false),
		[state, setState] = useState(null),
		textChange = e => {
			let { name, value, checked } = e?.target;
			setState({
				...state,
				[name]: e.target.type === "checkbox" ? checked : value,
			});
		},
		fileInputRef = useRef();

	let handleChangeImage = e => {
		const file = e.target.files[0];
		let err = "";

		if (!file) return (err = `File, ${file?.name} does not exist`);
		if (!file.type.includes("image"))
			return (err = `File, ${file?.name} format not supported`);

		if (err) {
			return toast.error(err);
		} else {
			// setLogo(file);

			setState({
				...state,
				[`${type === "Question" ? "question" : "option"}file`]: file,
				[`${type === "Question" ? "question" : "option"}Type`]: "file",
			});
		}
	};

	useEffect(() => {
		setState(datum);
	}, [datum]);

	const handleSubmit = async () => {
		// e?.preventDefault();
		if (!datum) return toast.info(`${type} is required`);
		setLoading(true);
		if (["Question", "Option"]?.includes(type)) await updateFunc(state);

		setLoading(false);
	};

	if (!isOpenUpdate) return null;

	return (
		<>
			<ModalContainer
				title={`Update ${type}`}
				width={"max-w-lg"}
				show={isOpenUpdate ? true : false}
				close={closeModalUpdate}>
				<form className="space-y-4">
					<div className="my-auto w-100">
						<span className="text-lg font-normal">{type}</span>
						<div className="w-full p-2 mt-2 border border-gray-600 rounded flex items-center gap-x-4">
							<input
								type="text"
								className="w-full p-2 border-0 border-gray-300 rounded outline-none focus:outline-none focus:shadow-none shadow"
								value={state?.[type === "Option" ? "option" : "question"]}
								onChange={textChange}
								name={[type === "Option" ? "option" : "question"]}
							/>
							{type === "Option" && (
								<input
									type="checkbox"
									name={`isCorrect`}
									className="mr-3 shadow"
									value={state?.isCorrect}
									checked={state?.isCorrect}
									onChange={textChange}
								/>
							)}
						</div>
						<div className="flex flex-col items-center gap-4 mt-8">
							{state?.[
								`${type === "Question" ? "question" : "option"}file`
							] && (
								<img
									src={
										state?.[
											`${type === "Question" ? "question" : "option"}file`
										]
											? URL.createObjectURL(
													state?.[
														`${type === "Question" ? "question" : "option"}file`
													]
											  )
											: null
									}
									alt=""
									className="h-48 w-auto rounded-sm border"
								/>
							)}
							<div className="flex gap-6 ms-auto">
								{state?.[
									`${type === "Question" ? "question" : "option"}file`
								] && (
									<button
										type="button"
										onClick={() =>
											setState({
												...state,
												[`${type === "Question" ? "question" : "option"}file`]:
													null,
												[`${type === "Question" ? "question" : "option"}Type`]:
													"text",
											})
										}
										className="h-8 w-24 bg-main rounded-xl text-white barlow text-base font-normal">
										Remove
									</button>
								)}
								<div>
									<input
										ref={fileInputRef}
										type="file"
										accept="image/*"
										style={{ display: "none" }}
										onChange={handleChangeImage}
										name="image"
									/>
									<button
										type="button"
										className="h-8 w-36 bg-main rounded-xl text-white barlow text-base font-normal"
										onClick={() => fileInputRef.current.click()}>
										Upload Image
									</button>
								</div>
							</div>
						</div>
						<div className="pt-4 flex">
							<Buttons
								// buttonType={"primary"}
								title={"Yes"}
								type="button"
								width={
									"w-fit ms-2 px-4 py-2 mr-2 border border-gray-600 text-[#30388F] rounded-md transition-all duration-300 hover:bg-[#30388F] hover:text-white focus:outline-none focus:shadow-outline cursor-pointer"
								}
								loading={loading}
								onClick={() => handleSubmit(state)}
							/>
							<Buttons
								// buttonType={"secondary"}
								title={"No"}
								type="button"
								width={
									"w-fit me-2 bg-[#F32424] hover:bg-orange-700 text-white font-bold py-2 px-4 rounded transition-all duration-300 focus:outline-none focus:shadow-outline cursor-pointer"
								}
								onClick={closeModalUpdate}
							/>
						</div>
					</div>
				</form>
			</ModalContainer>
		</>
	);
};
