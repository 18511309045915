import React from "react";
import { Link, useLocation } from "react-router-dom";
// import { LuPencil } from "react-icons/lu";
import ButtonDelete from "../button/Buttondelete";

const CardListThree = ({ title, icon, to, item }) => {
	let location = useLocation();
	return (
		<div className="bg-white shadow-lg p-4">
			<Link
				to={to}
				state={{ examType: location?.state?._id, subject: item?._id }}>
				<div className="max-w-sm mx-auto rounded overflow-hidden cursor-pointer transition transform hover:scale-105 flex justify-center items-center flex-col">
					<img className="w-20" src={icon} alt="Placeholder" />
					<div className="px-6 py-4">
						{/* <div className="font-bold text-xl mb-2"></div> */}
						<p className="text-[#1B1B1B] text-[20px] font-bold uppercase text-center">
							{title}
						</p>
					</div>
				</div>
			</Link>
			<div className="flex justify-end">
				<ButtonDelete datum={{ ...item, examType: location?.state?._id }} />
			</div>
		</div>

		// <div className="grid grid-cols-4 gap-8 justify-center items-center place-self-auto ">
		//     {cards.map((card, index) => (
		//         <div key={index} className="bg-white rounded-lg shadow-md p-4">
		//         <div className='flex flex-col justiify-center items-center text-center w-[250px] h-[150px]'>
		//             <img src={card.icon} alt="Card Image" className="icon mt-6" sizes={24} />
		//             <h1 className='font-semibold mt-12 text-[24px] text-center'>{card.title}</h1>
		//         </div>
		//       </div>
		//     ))}
		// </div>
	);
};

export default CardListThree;
