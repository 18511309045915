import React, { useContext, useEffect, useState } from "react";
import SelectField from "../../components/selectField/selectField";
import { LoadMore, MainPaginate } from "../../components/pagination/Pagination";
import Title from "../../components/title/title";
import DashBoardTable from "../../components/Table/dashboard";
import Img1 from "../../assets/Ellipse 957.png";
import Img2 from "../../assets/Ellipse 958.png";
import Img3 from "../../assets/users.png";
import Img4 from "../../assets/Ellipse 959.png";
import Img5 from "../../assets/Ellipse 960.png";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../data/Context";
import { manageCandidate } from "../../data/Reducers/CandidatesReducer";
import { LoadingLoader } from "../../components/button/button";

const Dashboard = () => {
	const DashBoardTableData = [
		{
			id: 1,
			image: Img1,
			name: "John Doe",
			email: "john.doe@example.com",
			phone: "09094863181",
		},
		{
			id: 2,
			image: Img2,
			name: "Jane Smith",
			email: "jane.smith@example.com",
			phone: "09094863181",
		},
		{
			id: 3,
			image: Img3,
			name: "Bob Johnson",
			email: "bob.johnson@example.com",
			phone: "09094863181",
		},
		{
			id: 4,
			image: Img4,
			name: "Alice Brown",
			email: "alice.brown@example.com",
			phone: "09094863181",
		},
		{
			id: 5,
			image: Img5,
			name: "Alice Brown",
			email: "alice.brown@example.com",
			phone: "09094863181",
		},
	];

	//  Pagination
	// const [currentPage, setCurrentPage] = useState(1);
	// const totalPages = 10;

	// const handlePageChange = page => {
	// 	if (page < 1) {
	// 		page = 1;
	// 	} else if (page > totalPages) {
	// 		page = totalPages;
	// 	}
	// 	setCurrentPage(page);
	// };

	let [data, setData] = useState(null),
		{ candidates, examType, subject, auth } = useSelector(s => s),
		{ numberWithCommas } = useContext(GlobalState),
		[itemOffset, setItemOffset] = useState(0),
		[page, setPage] = useState(1),
		[loading, setLoading] = useState(false),
		dispatch = useDispatch(),
		endOffset = itemOffset + 10;

	useEffect(() => {
		setData(candidates?.data);
	}, [candidates?.data]);

	if (!candidates?.data && !data) return <LoadingLoader />;

	if (!data) return;

	let currentItems = data?.docs?.slice(itemOffset, endOffset),
		pageCount = Math.ceil(data?.docs?.length / 10),
		handlePageClick = event => {
			const newOffset = (event.selected * 10) % data?.docs?.length;
			setItemOffset(newOffset);
			setPage(1 + event?.selected);
		},
		handleLoadMore = async () => {
			setLoading("loadmore");
			await dispatch(
				manageCandidate("get", {
					limit: data?.limit * data?.nextPage,
				})
			);
			setLoading(false);
		};

	return (
		<>
			<div className="max-w-[90%] mx-auto">
				<Title title="DASHBOARD" />
				<div className="bg-white my-8 gap-8 md:h-48 rounded-3xl shadow-lg flex-col md:flex-row flex justify-center items-center p-4 h-full w-full">
					{/* First shape */}
					<div className="flex items-center gap-x-4 w-full md:w-1/5">
						<img
							src={[require("../../assets/candidate.png")]}
							className="w-16 h-16"
							alt=""
						/>
						<div>
							<h1 className="text-sm font-medium text-[#707070]">
								Total Candidates
							</h1>
							<p className="text-lg font-bold">
								{numberWithCommas(data?.totalDocs || 0)}
							</p>
						</div>
					</div>
					<div className="border-2 border-gray-200 w-px h-48"></div>

					{/* Second shape */}
					<div className="flex items-center gap-x-4 w-full md:w-1/5">
						<img
							src={[require("../../assets/subjects.png")]}
							className="w-16 h-16 "
							alt=""
						/>
						<div>
							<h1 className="text-sm font-medium text-[#707070]">
								Total Exams
							</h1>
							<p className="text-lg font-semibold">
								{numberWithCommas(examType?.data?.totalDocs || 0)}
							</p>
						</div>
					</div>
					<div className="border-2 border-gray-200 w-px h-48"></div>

					{/* Third shape */}
					<div className="flex items-center gap-x-4 w-full md:w-1/5">
						<img
							src={[require("../../assets/questions.png")]}
							className="w-16 h-16 "
							alt=""
						/>
						<div>
							<h1 className="text-sm font-medium text-[#707070]">
								Total Subjects
							</h1>
							<p className="text-lg font-semibold">
								{numberWithCommas(subject?.data?.totalDocs || 0)}
							</p>
						</div>
					</div>
					<div className="border-2 border-gray-200 w-px h-48"></div>

					{/* Fourth shape */}
					<div className="flex items-center gap-x-4 w-full md:w-1/5">
						<img
							src={[require("../../assets/questions.png")]}
							className="w-16 h-16 "
							alt=""
						/>
						<div>
							<h1 className="text-sm font-medium text-[#707070]">
								Total Questions
							</h1>
							<p className="text-lg font-semibold">
								{numberWithCommas(auth?.questionsLength || 0)}
							</p>
						</div>
					</div>
				</div>

				<div className="bg-white h-auto rounded-3xl shadow-lg mt-10 p-6 ">
					<div className="flex md:flex-row justify-between items-center flex-col">
						<div>
							<h1 className="text-lg font-bold">Candidate List</h1>
						</div>
						<div>
							<SelectField />
						</div>
					</div>

					<div className="mt-8">
						<DashBoardTable data={currentItems} dummy={DashBoardTableData} />
					</div>

					{/* <div className='max-w-full mt-8 '>
                        <table className=' min-w-full overflow-hidden'>
                        <thead>
                            <tr>
                                <th className=" text-left px-6 py-3">Image</th>
                                <th className=" text-left px-6 py-3">Name</th>
                                <th className=" text-left px-6 py-3">Email</th>
                                <th className="text-left px-6 py-3">Phone</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item) => (
                                <tr key={item.id}>
                                <td className=" px-6 py-4">
                                    <img
                                    src={item.image}
                                    alt={item.name}
                                    className="h-12 w-12 object-cover rounded-full border border-[#FD5709]"
                                    />
                                </td>
                                <td className=" px-6 py-4">{item.name}</td>
                                <td className=" px-6 py-4">{item.email}</td>
                                <td className=" px-6 py-4">{item.phone}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>                    */}
					<div className="flex flex-col md:flex items-center justify-center mt-10">
						{/* <Pagination
							currentPage={currentPage}
							totalPages={totalPages}
							onPageChange={handlePageChange}
						/> */}
						<MainPaginate
							pageCount={pageCount}
							handlePageClick={handlePageClick}
						/>
					</div>
					<div className="mt-4 flex justify-center">
						<LoadMore
							next={page === pageCount && data?.next}
							loading={loading === "loadmore"}
							handleLoadMore={handleLoadMore}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default Dashboard;
