import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";

const Table = ({ data, to }) => {
	return (
		<div className="w-full overflow-x-auto">
			<table className="min-w-full table-auto">
				<thead>
					<tr className="">
						<th className=" px-6 py-3 text-left text-black uppercase tracking-wider">
							Fullname
						</th>
						<th className=" px-6 py-3 text-left text-black uppercase tracking-wider">
							Score
						</th>
						<th className=" px-6 py-3 text-left text-black uppercase tracking-wider">
							Grade
						</th>
						<th className="px-6 py-3 text-left text-black uppercase tracking-wider">
							Time Spent
						</th>
						<th className="px-6 py-3 text-left text-black uppercase tracking-wider">
							Exam Date
						</th>
						<th className="px-6 py-3 text-left text-black uppercase tracking-wider">
							Actions
						</th>
					</tr>
				</thead>
				<tbody>
					{data?.map((item, index) => (
						<tr
							key={index}
							//   className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
						>
							<td className="px-6 py-4 whitespace-nowrap">
								{item?.user?.lastName} {item?.user?.firstName}
							</td>
							<td className="px-6 py-4 whitespace-nowrap font-medium">
								{item?.score}
							</td>
							<td className="px-6 py-4 whitespace-nowrap font-medium">
								<GradeManager score={item?.score} />
							</td>
							<td className="px-6 py-4 whitespace-nowrap">{item?.timeSpent}</td>
							<td className="px-6 py-4 whitespace-nowrap">
								{moment(item?.createdAt).format("DD/MM/YYYY hh:mm A")}
							</td>
							<Link to={to} state={item}>
								<td className="px-6 py-4 whitespace-nowrap font-medium text-[#0052DD]">
									{item?.action}
								</td>
							</Link>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default Table;

export const GradeManager = ({ score }) => {
	let { grade } = useSelector(s => s),
		[grader, setGrader] = useState("");
	useEffect(() => {
		if (score && grade?.data) {
			let finder = grade?.data?.docs?.find(
				item =>
					Number(Math.floor(score)).toFixed(0) >=
						Number(Math.floor(item?.minimumScore)).toFixed(0) &&
					Number(Math.floor(score)).toFixed(0) <=
						Number(Math.floor(item?.maximumScore)).toFixed(2)
			);
			if (finder) setGrader(finder?.grade);
		}
	}, [score, grade?.data]);

	return <>{grader}</>;
};
