import React from 'react'

const TimeSpent = (timeSpent) => {
    const totalTime = Number("00:45:00"); 
    const spentTime = totalTime - timeSpent; 
  return (
    <div className='w-full'>
        <div className='flex justify-between items-center mb-2'>
            <h1 className='text-[#16.03] text-[#00284E] font-bold'>Time Spent</h1>
            <p className='text-[12.47] text-[#00284E] font-bold'>00:45:00</p>
        </div>

        <div className='w-full bg-white'>
            <div className='w-[602.77px] h-[26.71px] grid justify-center items-center bg-[#F2EFFF] rounded-lg' style={{width: `${spentTime}%`}}>
                <p className='text-[13px] font-bold'>
                    {spentTime}
                </p>
            </div>
        </div>
    </div>
  )
}

export default TimeSpent
