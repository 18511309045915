import React, {useState} from 'react'; 
import Success from './success';

const Modal = ({ isOpen, closeModal}) => {

  const [inputValue, setInputValue] = useState('');
  const [isModalOpenSuccess, setIsModalOpenSuccess] = useState(false); 

  const openModalSuccess = () =>{
      setIsModalOpenSuccess(true)
  }

  const closeSuccess = () =>{
    setIsModalOpenSuccess(false)
  }

  const handleInputChange = function(ev){
    setInputValue(ev.target.value); 
  }
  if (!isOpen) return null; 

  return (
    <div className='fixed top-0 left-0 w-full h-full flex flex-col items-center justifty-center z-50  bg-[rgba(0,0,0,0.2)]'>
        <div className='relative  bg-white p-6  shadow-lg m-auto w-1/3 h-80'>
            <h2 className='text-center text-3xl font-bold text-black'>EDIT TYPE</h2>
            <div className='mt-14'>
                <label htmlFor="input" className='font-semibold '>Rename Exam Type</label>
                <input 
                type="text"
                id='input'
                className='border rounded w-full py-2 px-3 text-gray-700 my-4'
                value={inputValue}
                onChange={handleInputChange}
             />
            </div>

             <div className='flex justify-center items-center gap-x-4 my-6'>
                <button 
                  className='px-4 py-2 mr-2 border border-gray-600 text-[#30388F] rounded-md transition-all duration-300 hover:bg-[#30388F] hover:text-white focus:outline-none focus:shadow-outline cursor-pointer'
                  onClick={closeModal}
                  >
                    Cancel
                </button>
                <button 
                  className="bg-[#30388F] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition-all duration-300 focus:outline-none focus:shadow-outline cursor-pointer"
                  onClick={openModalSuccess}>
                    Save
                </button>
             </div>
            <Success isOpenSuccess={isModalOpenSuccess} closeSuccess={closeSuccess} />
        </div>
    </div>
  )
}

export default Modal
