import React from 'react'
import { useParams } from 'react-router-dom'

const ExamQuestion = () => {
    const params = useParams(); 
    console.log(params.id);  
  return (
    <div>
      <h1>Hello</h1>
      {params.id}
    </div>
  )
}

export default ExamQuestion
