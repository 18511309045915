import { createSlice } from "@reduxjs/toolkit";
import { clearErrors, returnErrors } from "./ErrorReducer";
import axios from "axios";
import { toast } from "react-toastify";

let initialState = {
	data: null,
	search: "",
	status: "",
	mainSearch: null,
	isFound: null,
};

export const resultSlice = createSlice({
	name: "result",
	initialState,
	reducers: {
		getSearchResult: (state, { payload }) => {
			state.mainSearch =
				payload?.search === state?.search
					? payload?.data || payload
					: state?.mainSearch;
			state.search_paginate =
				payload?.search === state?.search
					? payload?.paginate || null
					: state?.search_paginate;
			state.isFound = true;
		},
		getSearch: (state, { payload }) => {
			state.search = payload?.search || payload;
		},
		resetResultSearch: state => {
			state.search = "";
			state.mainSearch = [];
			state.search_paginate = null;
			state.isFound = false;
		},
		getResult: (state, { payload }) => {
			state.data = payload?.data || payload;
			state.paginate = payload?.paginate;
		},
		resultFail: state => {
			state.status = "";
			state.isFound = null;
		},
		logoutResult: state => {
			state.status = "";
			state.isFound = null;
			state.data = null;
			state.mainSearch = null;
			state.paginate = null;
		},
	},
});
export const {
	resultFail,
	getResult,
	deleteResult,
	addResult,
	getSearchResult,
	getSearch,
	resetResultSearch,
	updateResult,
	logoutResult,
} = resultSlice.actions;

export const manageResult = (type, data) => async dispatch => {
	dispatch(clearErrors());
	try {
		let res;

		if (type === "get") {
			res = await axios.get(
				`/api/v1/exam/results?type=all${
					data?.limit ? `&limit=${data?.limit}` : ""
				}`
			);
			dispatch(getResult(res.data));
		}
		if (type !== "get") {
			toast.success(res?.data?.message);
			setTimeout(() => {
				dispatch(resultFail());
			}, 1000);
		}
	} catch (err) {
		if (err) console.log({ error: err.response?.data, err });
		if (err?.response?.status === 429) toast.error(err?.response?.data);
		dispatch(resultFail());
		if (type && type !== "get") {
			let error = err.response?.data?.error;
			if (error && error?.length > 1) {
				dispatch(returnErrors({ error, status: err?.response?.status }));
			} else {
				toast.error(err?.response?.data?.message || err?.message);
			}
		}
	}
};
