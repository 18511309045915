import React, { useState, useEffect } from "react";
import Title from "../../components/title/title";
import { AiOutlineSearch } from "react-icons/ai";
import SelectField from "../../components/selectField/selectField";
import { LoadMore, MainPaginate } from "../../components/pagination/Pagination";
import Table from "../../components/Table/table";
import { LoadingLoader } from "../../components/button/button";
import { manageResult } from "../../data/Reducers/ResultReducer";
import { useSelector, useDispatch } from "react-redux";

const ViewResult = () => {
	const tableData = [
		{
			fullname: "Debbie Ella",
			score: "45/45(90%)",
			grade: "Excellent",
			time: "20 Min",
			date: "15 Oct 2023",
			action: "See Details",
		},
		{
			fullname: "Debbie Ella",
			score: "45/45(90%)",
			grade: "Excellent",
			time: "20 Min",
			date: "15 Oct 2023",
			action: "See Details",
		},
		{
			fullname: "Debbie Ella",
			score: "45/45(90%)",
			grade: "Excellent",
			time: "20 Min",
			date: "15 Oct 2023",
			action: "See Details",
		},
		{
			fullname: "Debbie Ella",
			score: "45/45(90%)",
			grade: "Excellent",
			time: "20 Min",
			date: "15 Oct 2023",
			action: "See Details",
		},
		{
			fullname: "Debbie Ella",
			score: "28/45(56%)",
			grade: "Average",
			time: "20 Min",
			date: "15 Oct 2023",
			action: "See Details",
		},
		{
			fullname: "Debbie Ella",
			score: "45/45(90%)",
			grade: "Excellent",
			time: "20 Min",
			date: "15 Oct 2023",
			action: "See Details",
		},
		{
			fullname: "Debbie Ella",
			score: "45/45(90%)",
			grade: "Excellent",
			time: "20 Min",
			date: "15 Oct 2023",
			action: "See Details",
		},
		{
			fullname: "Debbie Ella",
			score: "15/45(30%)",
			grade: "Poor",
			time: "20 Min",
			date: "15 Oct 2023",
			action: "See Details",
		},
		{
			fullname: "Debbie Ella",
			score: "45/45(90%)",
			grade: "Excellent",
			time: "20 Min",
			date: "15 Oct 2023",
			action: "See Details",
		},
		{
			fullname: "Debbie Ella",
			score: "45/45(90%)",
			grade: "Excellent",
			time: "20 Min",
			date: "15 Oct 2023",
			action: "See Details",
		},
		{
			fullname: "Debbie Ella",
			score: "28/45(56%)",
			grade: "Average",
			time: "20 Min",
			date: "15 Oct 2023",
			action: "See Details",
		},
	];

	const [inputValue, setInputValue] = useState("");
	const [selectedOption, setSelectedOption] = useState("");
	const [selectedOptionTwo, setSelectedOptionTwo] = useState("");

	const handleInputChange = ev => {
		setInputValue(ev.target.value);
	};

	const handleSelectChange = ev => {
		setSelectedOption(ev.target.value);
	};
	const handleSelectChangeTwo = ev => {
		setSelectedOptionTwo(ev.target.value);
	};

	//  Pagination
	// const [currentPage, setCurrentPage] = useState(1);
	// const totalPages = 10;

	// const handlePageChange = page => {
	// 	if (page < 1) {
	// 		page = 1;
	// 	} else if (page > totalPages) {
	// 		page = totalPages;
	// 	}
	// 	setCurrentPage(page);
	// };

	let [data, setData] = useState(null),
		{ result } = useSelector(s => s),
		[itemOffset, setItemOffset] = useState(0),
		[page, setPage] = useState(1),
		[loading, setLoading] = useState(false),
		dispatch = useDispatch(),
		endOffset = itemOffset + 10;

	useEffect(() => {
		dispatch(manageResult("get", null));
	}, [dispatch]);

	useEffect(() => {
		setData(result?.data);
	}, [result?.data]);

	if (!result?.data && !data) return <LoadingLoader />;

	if (!data) return;

	let currentItems = data?.docs?.slice(itemOffset, endOffset),
		pageCount = Math.ceil(data?.docs?.length / 10),
		handlePageClick = event => {
			const newOffset = (event.selected * 10) % data?.docs?.length;
			setItemOffset(newOffset);
			setPage(1 + event?.selected);
		},
		handleLoadMore = async () => {
			setLoading("loadmore");
			await dispatch(
				manageResult("get", {
					limit: data?.limit * data?.nextPage,
				})
			);
			setLoading(false);
		};

	return (
		<div>
			<div className="w-[85%] m-auto">
				<Title title="CANDIDATES RESULTS" />

				<div className="mt-8 flex gap-x-20  items-center">
					<div className="text-gray-600 flex items-center border-2 rounded-md w-1/2 ">
						<input
							type="text"
							className=" outline-none focus:outline-none border-0 py-2 pl-2  w-full ml-2"
							placeholder="Search name or e-mail"
							value={inputValue}
							onChange={handleInputChange}
						/>
						<span className="bg-blue-100 p-3">
							<AiOutlineSearch className="icon text-blue-500" size={30} />
						</span>
					</div>

					{/* Second Select */}
					<div className="">
						<select
							name="select"
							id="select"
							className="block rounded-md w-80 font-bold outline-none focus:outline-none py-3 pl-4 text-medium"
							value={selectedOption}
							onChange={handleSelectChange}>
							<option value="">Status: All</option>
							<option value="option 1">Option 1</option>
							<option value="option 2">Option 2</option>
							<option value="option 3">Option 3</option>
							<option value="option 4">Option 4</option>
						</select>
					</div>

					{/* Three select */}
					<div className="">
						<select
							name="select"
							id="select"
							className="block rounded-md w-80 font-bold outline-none focus:outline-none py-3 pl-4 text-medium"
							value={selectedOptionTwo}
							onChange={handleSelectChangeTwo}>
							<option value="">Grade: All</option>
							<option value="option 1">Option 1</option>
							<option value="option 2">Option 2</option>
							<option value="option 3">Option 3</option>
							<option value="option 4">Option 4</option>
						</select>
					</div>
				</div>
				<div className="bg-white shadow-lg rounded-lg w-full p-4 my-8">
					<div className="flex justify-between items-center mt-4">
						<h1 className="text-lg font-bold">Candidate List</h1>
						<div className="flex items-center gap-4">
							<SelectField />
							<SelectField />
						</div>
					</div>
					<div className="mt-8">
						<Table data={currentItems || tableData} to="/view-results/result" />
					</div>

					{/* <div className="my-8">
						<Pagination
							currentPage={currentPage}
							totalPages={totalPages}
							onPageChange={handlePageChange}
						/>
					</div> */}
					<div className="flex flex-col md:flex items-center justify-center mt-10">
						{/* <Pagination
							currentPage={currentPage}
							totalPages={totalPages}
							onPageChange={handlePageChange}
						/> */}
						<MainPaginate
							pageCount={pageCount}
							handlePageClick={handlePageClick}
						/>
					</div>
					<div className="mt-4 flex justify-center">
						<LoadMore
							next={page === pageCount && data?.next}
							loading={loading === "loadmore"}
							handleLoadMore={handleLoadMore}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ViewResult;
